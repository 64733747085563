import { GridService } from './../../services/grid.service';
import { GlobalService } from './../../services/global.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Recipe, RecipeUsed } from '../../dtos/recipe';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-find-where-used',
  templateUrl: './find-where-used.component.html',
  styleUrls: ['./find-where-used.component.scss']
})
export class FindWhereUsedComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  @ViewChild('searchtreelist') searchTree: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  recipes: RecipeUsed[] = [];
  selectedRowKeys: any[] = [];
  gridMaxHeight: number;
  dataSource: DataSource;

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(
    private globalService: GlobalService,
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private estimatingService: EstimatingService,
    public gridService: GridService) {
  }

  ngOnInit() {
    this.setWidths();

    this.estimatingService.recipeLines.filter(i => i.recipeItemId === this.recipe.id).forEach(recipeLine => {
      const recipe = this.estimatingService.recipes.find(i => i.id === recipeLine.recipeId);
      this.recipes.push({
        recipeId: recipe.id,
        recipeLineId: recipeLine.id,
        recipeCode: recipe.recipeCode,
        description: recipe.description,
        quantity: recipeLine.quantity,
        isLocked: recipe.isLocked,
        isActive: recipe.isActive,
        phaseId: recipeLine.phaseId
      });
    });

    this.dataSource = new DataSource({
      key: 'recipeLineId',
      load: () => this.recipes,
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.updateRecipeLine(encodeURIComponent(key), { quantityString: values.quantity.toString(), quantity: values.quantity }).subscribe({
              next: (res) => {
                const recipeLine = this.estimatingService.recipeLines.find(i => i.id === key);
                recipeLine.quantity = values.quantity;
                recipeLine.quantityString = values.quantity.toString();
                this.notiService.showWarning('Refresh may be needed!');
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.gridMaxHeight = window.innerHeight - 250;
  }

  isEditVisible(e) {
    if (e.row.data.isLocked) {
      return false;
    }
    return true;
  }

  isLockVisible(e) {
    if (e.row.data.isLocked) {
      return true;
    }
    return false;
  }

  close() {
    this.activeModal.dismiss();
  }

  select() {
    const selectedRecipe = this.estimatingService.recipes.find(i => i.id === this.selectedRowKeys[0]);
    this.activeModal.close(selectedRecipe);
  }
}
