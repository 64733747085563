import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MaintenanceHelperService } from './maintenance-helper.service';

@Component({
  selector: 'js-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  sections: string[];
  priceFileAdmin: boolean;

  constructor(public helper: MaintenanceHelperService,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('PriceFile') === 'Admin') {
      this.priceFileAdmin = true;
    } else {
      this.helper.sections = ['Vendors'];
    }
    this.helper.selectedSection = 'Vendors';
  }

  tabSelected(e) {
    this.helper.selectedSection = e.itemData;
  }
}
