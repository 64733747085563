<div class="modal-header">
  <h2 class="modal-title">Job Search</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div *ngIf="currentCompany.userTypeId !== userType.Client && currentCompany.userTypeId !== userType.Associate"
      class="card-header">

      <aside class="aside2 marginRight25">
        <div class="row mb-0">
          <dx-text-box [(value)]="searchString" [width]="130" valueChangeEvent="keyup"
            (onValueChanged)="runFilter($event)" [showClearButton]="true" class="filterText">
          </dx-text-box>
        </div>
      </aside>

      <aside *ngIf="!isRestrictedForm" class="aside3">
        <button *ngIf="!templatesOnly" type="button" class="btn btn-sm btn-primary"
          (click)="getTemplates()">Templates</button>
        <button *ngIf="templatesOnly" type="button" class="btn btn-sm btn-primary orange"
          (click)="getTemplates()">Templates <i class="material-icons">done_outline</i></button>
      </aside>

      <aside class="aside3">
        <button *ngIf="!myJobsOnly" type="button" class="btn btn-sm btn-primary" (click)="getMyJobs()">My Jobs</button>
        <button *ngIf="myJobsOnly" type="button" class="btn btn-sm btn-primary orange" (click)="getMyJobs()"
          [disabled]="canOnlySeeMyJobs">My Jobs <i class="material-icons">done_outline</i></button>
      </aside>

      <aside *ngIf="!isRestrictedForm" class="activeOnlyClass">
        <label for="includeInactive" class="me-1">Include Inactive</label>
        <dx-check-box [(value)]="includeInactive" (onValueChanged)="activeJobsClicked()"> </dx-check-box>
      </aside>

      <aside *ngIf="!isRestrictedForm && isTrackingActive" class="activeOnlyClass">
        <label for="showJobsHandedOver" class="me-1">Handed Over</label>
        <dx-check-box [(value)]="showJobsHandedOver" (onValueChanged)="showJobsHandedOverClick($event)"> </dx-check-box>
      </aside>
    </div>

    <div class="card-body">

      <!-- Loading Spinner -->
      <js-loading-spinner *ngIf="loading"></js-loading-spinner>

      <dx-data-grid #dataGrid *ngIf="!loading" [dataSource]="jobData" [remoteOperations]="false" [height]="gridHeight"
        [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true" [showRowLines]="true"
        [focusedRowKey]="selectedJobNumber" [selectedRowKeys]="selectedJobNumber" [hoverStateEnabled]="true"
        [wordWrapEnabled]="true" [rowAlternationEnabled]="false" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
        (onSelectionChanged)="onSelectionChanged($event)">

        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="jobId" [visible]='false'></dxi-column>

        <dxi-column dataField="jobNumber" [width]="130" alignment="center" sortOrder="asc"></dxi-column>

        <dxi-column *ngIf="currentCompany.userTypeId !== userType.Associate && !isRestrictedForm"
          dataField="contractName"></dxi-column>

        <dxi-column dataField="jobAddressString" caption='Site Address'></dxi-column>
      </dx-data-grid>

    </div>
  </div>
</div>
