import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-re-calc-selling',
  templateUrl: './re-calc-selling.component.html',
  styleUrls: ['./re-calc-selling.component.scss']
})
export class ReCalcSellingComponent implements OnInit, OnDestroy {
  @Input() recipeParentId: number;

  @Output() toolboxClose: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  reCostingDate: Date;
  updateOptionLists = false;
  loading = false;
  titleText: string;
  popupVisible = true;
  selectedRecipeIds: number[] = [];
  popupWidth = 300;
  popupHeight = 320;
  recordCount: number;

  constructor(
    private notiService: NotificationService,
    public estimatingService: EstimatingService
  ) { }

  ngOnInit() {
    this.titleText = 'Recalc ' + (this.recipeParentId ? 'this class' : 'selected classes');
    this.reCostingDate = this.estimatingService.costingDate;

    if (!this.recipeParentId) {
      this.popupHeight = window.innerHeight - 85;
      this.popupWidth = 700;
    } else {
      this.selectedRecipeIds.push(this.recipeParentId);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  recostRecipesGo() {
    this.loading = true;
    this.recordCount = 1;
    let counter = 0;

    this.selectedRecipeIds.forEach(selectedRecipeId => {
      setTimeout(() => {
        this.subscriptions.push(
          this.estimatingService.recostRecipes(this.updateOptionLists, this.reCostingDate, selectedRecipeId, null)
            .subscribe({
              next: () => {
                this.recordCount++;

                if (this.recordCount === (this.selectedRecipeIds.length + 1)) {
                  this.estimatingService.costingDate = this.reCostingDate;
                  this.estimatingService.recipeSellingRates = [];

                  this.estimatingService.currentCostingDateString = this.estimatingService.costingDate.getFullYear() + '-'
                    + ('0' + (this.estimatingService.costingDate.getMonth() + 1)).toString().slice(-2) + '-'
                    + ('0' + this.estimatingService.costingDate.getDate()).slice(-2);

                  this.loading = false;
                  this.popupVisible = false;
                  this.toolboxClose.emit(true); // close and send refresh notification
                }
              }, error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }, 4000 * counter);
      counter++;
    });
  }

  closeToolbox(event) {
    if (this.loading) {
      event.cancel = true;
      this.notiService.showWarning('Please wait for re-price to complete...');
    } else {
      this.popupVisible = false;
      this.toolboxClose.emit(false);
    }
  }

  setSelectedRecipes(event) {
    this.selectedRecipeIds = event;
  }
}
