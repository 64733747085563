import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { PriceFileService } from '../services/felixApi/price-file.service';
import { PriceFileItemGroupsComponent } from './price-file-item-groups/price-file-item-groups.component';
import { PriceFileItemsComponent } from './price-file-items/price-file-items.component';
import { CostCentreFooterComponent } from './price-file-item-groups/cost-centre-footer/cost-centre-footer.component';
import { CalcEstExtraComponent } from './calc-est-extra/calc-est-extra.component';
import { SetPreferredVendorComponent } from './set-preferred-vendor/set-preferred-vendor.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        PriceFileItemGroupsComponent,
        PriceFileItemsComponent,
        CostCentreFooterComponent,
        CalcEstExtraComponent,
        SetPreferredVendorComponent
    ],
    providers: [
        PriceFileService
    ]
})
export class PriceFileItemsModule { }
