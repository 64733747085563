import { Recipe } from './../../dtos/recipe';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-copy-recipe',
  templateUrl: './copy-recipe.component.html',
  styleUrls: ['./copy-recipe.component.scss']
})
export class CopyRecipeComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  subscriptions: Subscription[] = [];
  newCode: string;
  loading: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private estimatingService: EstimatingService) {
  }

  ngOnInit() {
    this.newCode = this.recipe.recipeCode + '-copy';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this._activeModal.dismiss();
  }

  copyRecipe() {
    this.loading = true;
    this.subscriptions.push(
      this.estimatingService.copyRecipe(this.recipe.id, this.newCode)
        .subscribe({
          next: (newRecipe) => {
            this._activeModal.close(newRecipe);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }
}
