<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="dataGrid" class="max-w-800 m-auto tabbed-grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'Phases')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-row-dragging [onReorder]="onReorder" [allowDropInsideItem]="false" [allowReordering]="true"
      [showDragIcons]="true"></dxo-row-dragging>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="phaseCode" width="100">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="isShowOnPO" dataType="boolean" caption="Show on Order" [width]="120"> </dxi-column>

    <dxi-column dataField="orderNo" [visible]="false" sortOrder="asc">
    </dxi-column>
  </dx-data-grid>
</div>
