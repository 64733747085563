import { Recipe } from './../../dtos/recipe';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-sub-recipe',
  templateUrl: './sub-recipe.component.html',
  styleUrls: ['./sub-recipe.component.scss']
})
export class SubRecipeComponent implements OnInit {
  @Input() recipe: Recipe;

  constructor(private activeModal: NgbActiveModal) {
     }

  ngOnInit() {
  }

  refresh() {}

  close() {
      this.activeModal.close();
  }
}
