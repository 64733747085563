import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CostCentreSummaryComponent } from '../cost-centre-summary/cost-centre-summary.component';
import { AuthService } from '../../services/auth.service';
import { SetMarkupComponent } from '../set-markup/set-markup.component';
import { SetNotActiveComponent } from '../set-not-active/set-not-active.component';

@Component({
  selector: 'js-recipe-toolbox',
  templateUrl: './recipe-toolbox.component.html',
  styleUrls: ['./recipe-toolbox.component.scss']
})
export class RecipeToolboxComponent implements OnInit {
  @Input() recipeParentId: number;

  subscriptions: Subscription[] = [];
  titleText: string;
  showCalcSelling = false;
  showReplaceItem: boolean;
  recipesAdmin: boolean;

  constructor(private activeModal: NgbActiveModal,
    private authService: AuthService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.titleText = 'Recipe Toolbox';

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      this.recipesAdmin = true;
    }
  }

  recostRecipes() {
    this.showCalcSelling = true;
  }

  replaceItem() {
    this.showReplaceItem = true;
  }

  recipeCompare() {
    const modalRef = this.modalService.open(CostCentreSummaryComponent, { windowClass: 'modal-1500' });

    modalRef.result.then(() => {
      this.activeModal.dismiss();
    }, () => {
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  closeToolboxAfter(event) {
    this.showCalcSelling = false;
    this.showReplaceItem = false;

    if (event) {
      this.activeModal.close();
    }
  }

  setMarkup() {
    const modalRef = this.modalService.open(SetMarkupComponent, { windowClass: 'modal-edit' });

    modalRef.result.then(() => {
      this.activeModal.close();
    }, () => {
    });
  }

  setNotActive() {
    const modalRef = this.modalService.open(SetNotActiveComponent, { windowClass: 'modal-edit' });

    modalRef.result.then(() => {
      this.activeModal.close();
    }, () => {
    });
  }
}
