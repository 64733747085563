<div class="modal-header">
  <h4 class="modal-title">Set the preferred vendor</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <dx-select-box [dataSource]="districts" displayExpr="description" valueExpr="id" [(value)]='districtId'
    placeholder="Choose Price Book..." >
  </dx-select-box>

  <dx-select-box [dataSource]="vendors" displayExpr="vendorName" valueExpr="id" [(value)]='vendorId'
    placeholder="Choose Vendor..." class="mt-1" [searchEnabled]="true">
  </dx-select-box>

  <dx-data-grid *ngIf="!loading" #poGrid id="poGrid" [height]="gridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedCostCentres" [hoverStateEnabled]="true" class="mt-2">

    <dxo-search-panel [visible]="true" location="after" [width]="150"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-sorting mode='single'></dxo-sorting>

    <dxo-selection [allowSelectAll]="true" showCheckBoxesMode='always' mode="multiple">
    </dxo-selection>

    <dxi-column dataField="priceFileCode" caption="CC" dataType="string" width="100">
    </dxi-column>

    <dxi-column dataField="description" dataType="string">
    </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="runUpdate()"
    [disabled]="!selectedCostCentres.length || !vendorId || !districtId">
    Run Update
  </button>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
