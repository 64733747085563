import { NotificationService } from './../../services/notification.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Recipe } from './../../dtos/recipe';
import { PriceFileService } from './../../services/felixApi/price-file.service';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { SelectedItem } from '../../dtos/selected-item';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-add-multiple-items',
  templateUrl: './add-multiple-items.component.html',
  styleUrls: ['./add-multiple-items.component.scss']
})
export class AddMultipleItemsComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  @Output() popupClose: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  loading = false;
  recipeData: CustomStore;
  selectedItems: SelectedItem[];
  selectedItem: SelectedItem;
  popupVisible = true;
  selectedRowKeys: any;
  gridHeight = 800;

  constructor(private priceFileService: PriceFileService,
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private estimatingService: EstimatingService) {
    this.addRecipesFromListGo = this.addRecipesFromListGo.bind(this);
  }

  ngOnInit() {
    this.recipeData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.priceFileService.recipeAndItems.filter(i => i.isActive)
    });

    this.gridHeight = window.innerHeight - 200;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  addRecipesFromListGo(e) {
    e.cancel = true;
    this.selectedItems = [];

    e.changes.forEach(element => {
      if (element.data.quantity) {
        const priceFileItemWithRate = this.priceFileService.recipeAndItems.find(i => i.recipeCode === element.key);

        if (priceFileItemWithRate) {
          this.selectedItem = {
            recipeId: priceFileItemWithRate.recipeId ? priceFileItemWithRate.recipeId : null,
            priceFileItemId: priceFileItemWithRate.priceFileItemId ? priceFileItemWithRate.priceFileItemId : null,
            description: priceFileItemWithRate.description,
            unitOfMeasure: priceFileItemWithRate.unitOfMeasure,
            quantity: +element.data.quantity,
            recipeCode: priceFileItemWithRate.recipeCode
          };
          this.selectedItems.push(this.selectedItem);
        }
      }
    });

    // add the recipes selected
    if (this.selectedItems && this.selectedItems.length) {
      this.loading = true;

      this.subscriptions.push(
        this.estimatingService.addMultipleRecipeLines(this.recipe.id, this.selectedItems).subscribe({
          next: () => {
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
      );
    }
  }

  close() {
    if (this.loading) {
      this.notiService.showWarning('Please wait for process to complete...');
    } else {
      this.activeModal.dismiss();
    }
  }
}
