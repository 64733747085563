import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-set-not-active',
  templateUrl: './set-not-active.component.html',
  styleUrls: ['./set-not-active.component.scss']
})
export class SetNotActiveComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loading: boolean;
  selectedRecipeIds: number[];
  selectedOption: string;
  selectedOptions: string[] = ['Mark NOT Active', 'Mark Active'];

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private estimatingService: EstimatingService) {
  }

  ngOnInit() {
    this.selectedOption = 'Mark NOT Active';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this._activeModal.dismiss();
  }

  setSelectedRecipes(event) {
    this.selectedRecipeIds = event;
  }

  runUpdate() {
    this.loading = true;

    this.subscriptions.push(
      this.estimatingService.setActiveNotActive(this.selectedOption === 'Mark Active', this.selectedRecipeIds)
        .subscribe({
          next: () => {
            this.loading = false;
            this._activeModal.close();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }
}
