import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { GlobalService } from '../../services/global.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GridService } from '../../services/grid.service';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-qty-size-control',
  templateUrl: './qty-size-control.component.html',
  styleUrls: ['./qty-size-control.component.scss']
})
export class QtySizeControlComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  loading = true;
  popupVisible = false;
  value: any[] = [];
  unitsOfMeasure: UnitOfMeasure[] = [{ id: null, description: '', costIsPer: null, isDoNotRecost: false }];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    if (this.maintenanceService.unitOfMeasures && this.maintenanceService.unitOfMeasures.length) {
      this.unitsOfMeasure = this.unitsOfMeasure.concat(this.maintenanceService.unitOfMeasures);
      this.loading = false;
      this.setUpDataSource();
    } else {
      this.subscriptions.push(
        this.maintenanceService.getUnitOfMeasures().subscribe({
          next: (unitsOfMeasure) => {
            this.unitsOfMeasure = this.unitsOfMeasure.concat(unitsOfMeasure);
            this.loading = false;
            this.setUpDataSource();
          }, error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }

    this.setUpDataSource();
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getQtySizeControls(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addQtySizeControl(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateQtySizeControl(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteQtySizeControl(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  ngOnDestroy() {
    this.maintenanceService.qtySizeControls = null;

    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
