<dx-popup class="popup" [width]="800" [height]="800" [showTitle]="true" title="Purchase Order Footers"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="true" (onHiding)="onHiding()">
  <div *dxTemplate="let data of 'content'" (onExporting)="gridService.onExporting($event, 'Footers')">

    <dx-data-grid id="dataGrid" class="max-w-800 m-auto grid-height" [dataSource]="dataSource"
      [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="true" [wordWrapEnabled]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true" location="before" [width]="150"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="full"></dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="description" sortOrder="asc">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="showFooterQRCode" caption="Show Footer Image / QR Code" dataType="boolean" width="150">
      </dxi-column>

      <dxi-column caption="Edit HTML Footer" width="100" alignment="center" editCellTemplate="editFooterHtmlTemplate"
        cellTemplate="editFooterHtmlTemplate">
      </dxi-column>
      <div *dxTemplate="let data of 'editFooterHtmlTemplate'" class="text-center">
        <dx-button icon="edit" (onClick)="openHtmlFooter(data)"></dx-button>
      </div>
    </dx-data-grid>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="800" [height]="600" [showTitle]="true" [title]="htmlTitle" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="showHtmlPopup">
  <div *dxTemplate="let data of 'content'">
    <dx-html-editor #editor class="htmlClass" (valueChange)="valueChange($event)">
      <dxo-toolbar [multiline]="true">
        <dxi-item name="undo"></dxi-item>
        <dxi-item name="redo"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="size"
          [acceptedValues]="['6pt', '7pt', '8pt', '9pt', '10pt', '11pt', '12pt', '14pt', '18pt', '24pt', '36pt']">
        </dxi-item>
        <dxi-item name="font"
          [acceptedValues]="['Arial', 'Calibri', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']">
        </dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="bold"></dxi-item>
        <dxi-item name="italic"></dxi-item>
        <dxi-item name="strike"></dxi-item>
        <dxi-item name="underline"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="alignLeft"></dxi-item>
        <dxi-item name="alignCenter"></dxi-item>
        <dxi-item name="alignRight"></dxi-item>
        <dxi-item name="alignJustify"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="orderedList"></dxi-item>
        <dxi-item name="bulletList"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="color"></dxi-item>
        <dxi-item name="background"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="clear"></dxi-item>
      </dxo-toolbar>
    </dx-html-editor>

    <dx-button *ngIf="!savinghtml" class="mt-2" text="Save" type="default" (onClick)="saveHtmlFooter()"></dx-button>
    <div *ngIf="savinghtml">Saving...</div>
  </div>
</dx-popup>
