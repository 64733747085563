import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { RecipePriceFileItem } from '../../dtos/recipe-pricefile-item';

@Component({
  selector: 'js-item-replace',
  templateUrl: './item-replace.component.html',
  styleUrls: ['./item-replace.component.scss']
})
export class ItemReplaceComponent implements OnInit, OnDestroy {
  @Input() recipeParentId: number;

  @Output() toolboxClose: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  loading = true;
  titleText: string;
  oldRecipeCode: string;
  oldPriceFileItemId: number;
  oldRecipeId: number;
  newRecipeCode: string;
  newPriceFileItemId: number;
  newRecipeId: number;
  recipeAndItems: RecipePriceFileItem[];
  dropDownOptions: object;
  updateLockedRecipes = false;
  recipesAdmin: boolean;
  explodeAddedRecipe = false;
  deleteZeroQtyItemsAfterExplode = false;

  constructor(
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService,
    public estimatingService: EstimatingService,
    private authService: AuthService
  ) {
    this.dropDownOptions = { width: 800, minHeight: 500 };
    this.onPrevSelectionChanged = this.onPrevSelectionChanged.bind(this);
    this.onNewSelectionChanged = this.onNewSelectionChanged.bind(this);
  }

  ngOnInit() {
    this.titleText = 'Find & Replace Item in ' + (this.recipeParentId ? 'this class' : 'All recipes');

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      this.recipesAdmin = true;
    }

    // allow spinner to start
    setTimeout(() => {
      this.getData();
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getData() {
    const baseDistrict = this.maintenanceService.districts.find(i => !i.districtParentId);

    this.subscriptions.push(
      this.estimatingService.getRecipesAndItems(true)
        .subscribe({
          next: () => {
            this.recipeAndItems = this.estimatingService.getRecipeLinesForDistrict(baseDistrict, false);
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key.split(';')[1];
  }

  onPrevSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.oldRecipeCode = event.selectedRowsData[0].recipeCode;
      this.oldRecipeId = event.selectedRowsData[0].recipeId;
      this.oldPriceFileItemId = event.selectedRowsData[0].priceFileItemId;
      e.component.close();
    }
  }

  onNewSelectionChanged(e, event) {
    if (event.selectedRowKeys.length > 0) {
      this.newRecipeCode = event.selectedRowsData[0].recipeCode;
      this.newRecipeId = event.selectedRowsData[0].recipeId;
      this.newPriceFileItemId = event.selectedRowsData[0].priceFileItemId;
      e.component.close();
    }
  }

  findAndReplaceGo() {
    this.loading = true;

    this.subscriptions.push(
      this.estimatingService.findAndReplaceItem(this.oldPriceFileItemId, this.newPriceFileItemId, this.oldRecipeId,
        this.newRecipeId, this.recipeParentId, this.updateLockedRecipes, this.explodeAddedRecipe, this.deleteZeroQtyItemsAfterExplode)
        .subscribe({
          next: () => {
            this.loading = false;
            this.toolboxClose.emit(true); // close and send refresh notification
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  closeToolbox(event) {
    if (this.loading) {
      event.cancel = true;
      this.notiService.showWarning('Please wait for replace to complete...');
    } else {
      this.toolboxClose.emit(false);
    }
  }
}
