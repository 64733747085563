import { CompanyMargin } from './../../dtos/company-margin';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { NotificationService } from '../../services/notification.service';
import { CompanyMarginService } from '../../services/felixApi/company-margin.service';

@Component({
  selector: 'js-set-markup',
  templateUrl: './set-markup.component.html',
  styleUrls: ['./set-markup.component.scss']
})
export class SetMarkupComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  selectedMarkupId: number;
  loading: boolean;
  companyMargins: CompanyMargin[];
  selectedRecipeIds: number[];

  constructor(private _activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private companyMarginService: CompanyMarginService,
    private estimatingService: EstimatingService) {
  }

  ngOnInit() {
    this.companyMargins = this.companyMarginService.companyMarginsForRecipes;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this._activeModal.dismiss();
  }

  setSelectedRecipes(event) {
    this.selectedRecipeIds = event;
  }

  runUpdate() {
    this.loading = true;

    this.subscriptions.push(
      this.estimatingService.setMarkUps(this.selectedMarkupId, this.selectedRecipeIds)
        .subscribe({
          next: () => {
            this.loading = false;
            this._activeModal.close();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }
}
