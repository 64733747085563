import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { RecipesComponent } from './recipes.component';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { RecipeClassesComponent } from './recipe-classes.component';
import { RecipeLinesComponent } from './recipe-lines.component';
import { ReCalcSellingComponent } from './re-calc-selling/re-calc-selling.component';
import { ItemReplaceComponent } from './item-replace/item-replace.component';
import { RecipeToolboxComponent } from './recipe-toolbox/recipe-toolbox.component';
import { AddMultipleItemsComponent } from './add-multiple-items/add-multiple-items.component';
import { SubRecipeComponent } from './sub-recipe/sub-recipe.component';
import { RecipeSearchComponent } from './recipe-search/recipe-search.component';
import { ExplodeInMemoryComponent } from './explode-in-memory/explode-in-memory.component';
import { UploadRecipeLinesComponent } from './upload-recipe-lines/upload-recipe-lines.component';
import { FindWhereUsedComponent } from './find-where-used/find-where-used.component';
import { CostCentreSummaryComponent } from './cost-centre-summary/cost-centre-summary.component';
import { SetMarkupComponent } from './set-markup/set-markup.component';
import { SelectRecipesComponent } from './select-recipes/select-recipes.component';
import { CopyRecipeComponent } from './copy-recipe/copy-recipe.component';
import { SetNotActiveComponent } from './set-not-active/set-not-active.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    RecipesComponent,
    RecipeClassesComponent,
    RecipeLinesComponent,
    ReCalcSellingComponent,
    ItemReplaceComponent,
    RecipeToolboxComponent,
    AddMultipleItemsComponent,
    SubRecipeComponent,
    RecipeSearchComponent,
    ExplodeInMemoryComponent,
    UploadRecipeLinesComponent,
    FindWhereUsedComponent,
    CostCentreSummaryComponent,
    SetMarkupComponent,
    SelectRecipesComponent,
    CopyRecipeComponent,
    SetNotActiveComponent
  ],
  providers: [
    EstimatingService
  ]
})
export class RecipesModule { }
