<div class="modal-header">
  <h4 class="modal-title">Upload lines into {{recipe.recipeCode}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <div id="fileuploader-container">
    <dx-file-uploader selectButtonText="Select .csv file" labelText="or drop file here" [multiple]="false" accept="*"
      [(value)]="value" uploadMode="useForm" [allowedFileExtensions]="['.csv']">
    </dx-file-uploader>
  </div>


  <dx-check-box [(ngModel)]="deleteExistingLines" text="Delete existing lines" [rtlEnabled]="true" class="mt-3 ms-3">
  </dx-check-box>

  <!-- <dx-button *ngIf="excelLoadTypeId === 1 || excelLoadTypeId === 2" id="sample-button" text="Sample" type="default"
    stylingMode="outlined" (onClick)="downloadSample()">
  </dx-button> -->
</div>

<div class="modal-footer">
  <dx-button id="load-button" text="Load" type="success" (onClick)="uploadClick()">
  </dx-button>
</div>
