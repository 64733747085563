<div class="modal-header">
  <h2 class="modal-title">Search</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-tree-list #searchtreelist id="searchtreelist" [dataSource]="allRecipes" keyExpr="id" class="gridHeight"
    parentIdExpr="recipeParentId" remoteOperations="false" [showRowLines]="false" [showColumnLines]="false"
    [showBorders]="true" [columnAutoWidth]="true" [rootValue]=null [wordWrapEnabled]="true"
    [(selectedRowKeys)]="selectedRowKeys" [ngStyle]="{'max-height': 'calc(' + gridMaxHeight + 'px)'}"
    [(expandedRowKeys)]="expandedRowKeys">

    <dxo-search-panel [visible]="true" [width]="300"></dxo-search-panel>
    <dxo-selection [mode]="selectionMode"></dxo-selection>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <!-- <dxo-column-chooser [enabled]="true"></dxo-column-chooser> -->

    <dxo-toolbar>
      <dxi-item location="after">
        <dx-button icon="download" (onClick)="download()">
        </dx-button>
      </dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>

    <dxi-column dataField="description" caption="Class"> </dxi-column>
    <dxi-column dataField="recipeCode" caption="Code" [width]="120"> </dxi-column>
    <dxi-column dataField="rate" caption="Cost" dataType="number" [width]="110" format="#,##0.00"> </dxi-column>
    <dxi-column dataField="sellingPrice" dataType="number" [width]="110" format="#,##0.00"> </dxi-column>
    <dxi-column dataField="unitOfMeasure" caption="Units" [width]="120"> </dxi-column>

  </dx-tree-list>
</div>

<div class="modal-footer">
  <!-- <button class="btn btn-sm btn-secondary" (click)="download()">Download</button> -->
  <button class="btn btn-sm btn-primary" (click)="select()" [disabled]="!selectedRowKeys.length">Select</button>
</div>
