import { AuthService } from './../../services/auth.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Recipe } from '../../dtos/recipe';

@Component({
  selector: 'js-select-recipes',
  templateUrl: './select-recipes.component.html',
  styleUrls: ['./select-recipes.component.scss']
})
export class SelectRecipesComponent implements OnInit {
  @Input() selectMultiple: boolean;
  @Input() onlyClasses: boolean;

  @Output() emitSelection: EventEmitter<any> =
    new EventEmitter<any>();

  allRecipes: Recipe[] = [];
  selectedRowKeys: any[] = [];
  gridHeight: number;
  expandedRowKeys: Number[] = [];
  gridString: any;
  selectionMode: string;
  selectionType: string;
  classDescription = 'Class';

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(
    private estimatingService: EstimatingService,
    private authService: AuthService) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  ngOnInit() {
    this.selectionMode = this.selectMultiple ? 'multiple' : 'single';
    this.selectionType = this.onlyClasses ? 'all' : this.selectMultiple ? 'leavesOnly' : 'excludeRecursive';
    this.setWidths();


    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      // note all recipeClasses can be seen
      this.allRecipes = this.allRecipes.concat(this.estimatingService.recipeGroups);
    }
    else {
      this.allRecipes = this.allRecipes.concat(this.estimatingService.recipeGroups.filter(i => !i.isAdminOnly));
    }

    if (!this.onlyClasses) {
      this.allRecipes = this.allRecipes.concat(this.estimatingService.recipes);
      this.classDescription = 'Class/Recipe'
    }
  }

  setWidths() {
    this.gridHeight = window.innerHeight - 400;
  }

  onSelectionChanged(e) {
    const selectedKeys: number[] = e.component.getSelectedRowKeys(this.selectionType);
    if (this.selectMultiple) {
      this.emitSelection.emit(selectedKeys);
    } else {
      const selectedRecipe = this.allRecipes.find(i => i.id === selectedKeys[0]);
      this.emitSelection.emit(selectedRecipe);
    }
  }
}
