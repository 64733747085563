<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Summary</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid *ngIf="!loading" class="mt-3" #treeList id="tree-list" [dataSource]="dataSource" [height]="gridHeight"
    [columns]='gridColumns' [remoteOperations]="false" [showRowLines]="false" [showColumnLines]="true"
    [showBorders]="true" [columnAutoWidth]="false" [wordWrapEnabled]="true" [allowColumnResizing]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)" [summary]="summary" (onExporting)="gridService.onExporting($event, 'Summary')">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-paging [enabled]="false"> </dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
  </dx-data-grid>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingSpinnerOn" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
