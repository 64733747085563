export class CompanyConfiguration {
    id: number;
    configurationId: number;
    configurationValue: number;
    configurationString: string;
    attachmentTypeId: number;
    attachment: Blob;
    attachmentName: string;

    configBoolean: boolean;
}
