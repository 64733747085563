<div class="modal-header">
  <h4 class="modal-title">{{recipe.recipeCode}}: {{recipe.description}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid #inMemoryGrid id="inMemoryGrid" [dataSource]="dataSource" width="100%" [allowColumnReordering]="false"
    [allowColumnResizing]="false" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [wordWrapEnabled]="true" (onRowInserted)="$event.component.navigateToRow($event.key)"
    [scrolling]="{ mode: 'virtual', preloadEnabled: true }" (onCellPrepared)="onCellPrepared($event)"
    [ngStyle]="{'max-height': 'calc(' + gridMaxHeight + 'px)'}" (onToolbarPreparing)="onToolbarPreparing($event)" (onExporting)="gridService.onExporting($event, 'Exploded-Recipe')">

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="false" expandMode="rowClick">
    </dxo-grouping>

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="Cost Centre"
      [groupIndex]="0" groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [groupIndex]="1"
      groupCellTemplate="subgroupCellTemplate">
    </dxi-column>

    <dxi-column dataField="recipeItemId" dataType="number" [visible]='false'> </dxi-column>

    <dxi-column dataField="recipeCode" dataType="string" caption="Item/Recipe" [width]="110">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [width]="descriptionWidth" cellTemplate="wrapTemplate">
    </dxi-column>

    <dxi-column dataField="unitOfMeasureId" dataType="string" caption="Units" [width]="80" [hidingPriority]="5">
      <dxo-lookup [dataSource]="unitOfMeasures" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="quantity" caption="Qty" dataType="number" [width]="80">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column caption="Total" [calculateCellValue]="calculateLineTotal" dataType="number" [width]="140"
      [format]="{ type: 'fixedPoint', precision: 2 }">
    </dxi-column>

    <dxi-column dataField="marginOverridePercent" caption='Mark-Up Override%' dataType="number" [width]="80"
      [format]="{ type: 'fixedPoint', precision: 2 }" alignment="center" [hidingPriority]="4">
    </dxi-column>

    <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="90" [hidingPriority]="3">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isToBeChecked" caption="To Be Checked" dataType="boolean" [width]="75" [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="comment" dataType="string"[hidingPriority]="1"> </dxi-column>


    <div *dxTemplate="let cellInfo of 'wrapTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let data of 'subgroupCellTemplate'">
      {{ getSubGroupTitle(data) }}
    </div>

    <dxo-summary>
      <dxi-total-item column="Total" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
        displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

  <div>Note: Empty recipes have been ignored</div>
</div>
