<dx-popup class="popup" [width]="600" [height]="285" [showTitle]="true" [title]="titleText" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [visible]="true" [shading]="false" (onHiding)="closeToolbox($event)">
  <div *dxTemplate="let data of 'content'">

    <div *ngIf="loading">
      Loading data, please wait...
      <js-loading-spinner></js-loading-spinner>
    </div>

    <div *ngIf="!loading">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeAndItems" [(value)]="oldRecipeCode"
        placeholder="Select item to replace" displayExpr="description" valueExpr="recipeCode"
        contentTemplate="contentTemplate" hint="Item to replace">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="recipeAndItems" keyExpr="recipeCode" [remoteOperations]="false" height="500px"
            [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
            [focusedRowKey]="oldRecipeCode" [wordWrapEnabled]="true" [selectedRowKeys]="[oldRecipeCode]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onPrevSelectionChanged(e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

            <dxi-column dataField="description"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>

      <br>

      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeAndItems" [(value)]="newRecipeCode"
        placeholder="Select new item" displayExpr="description" valueExpr="recipeCode"
        contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="recipeAndItems" keyExpr="recipeCode" [remoteOperations]="false" height="500px"
            [showBorders]="true" [selection]="{ mode: 'single' }" [focusedRowEnabled]="true"
            [focusedRowKey]="newRecipeCode" [wordWrapEnabled]="true" [selectedRowKeys]="[newRecipeCode]"
            [hoverStateEnabled]="true" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onNewSelectionChanged(e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

            <dxi-column dataField="description"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>

      <br>
      <br>

      <dx-check-box *ngIf="recipesAdmin" [(value)]="updateLockedRecipes" text="Update locked recipes" rtlEnabled="true">
      </dx-check-box>

      <br *ngIf="newRecipeId">

      <dx-check-box *ngIf="newRecipeId" [(value)]="explodeAddedRecipe" text="Explode added recipe" rtlEnabled="true"
        class="mt-2">
      </dx-check-box>

      <dx-check-box *ngIf="explodeAddedRecipe" [(value)]="deleteZeroQtyItemsAfterExplode" text="Delete exploded items with zero quantity"
        rtlEnabled="true" class="ms-3">
      </dx-check-box>

      <dx-button id="item-replace-button" text="Go" type="success" (onClick)="findAndReplaceGo()"
        [disabled]="!oldRecipeCode || !newRecipeCode">
      </dx-button>
    </div>
  </div>
</dx-popup>
