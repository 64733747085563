import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Job } from '../../dtos/job';
import { GlobalService } from '../global.service';
import { JobExtra } from '../../dtos/job-extra';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';
import { OrderLine } from '../../dtos/order-line';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private currentJobNo: string;
  jobs: Job[];
  currentJob: Job;
  jobsCompany: number;
  jobExtras: JobExtra[];
  currentJobExtra: JobExtra;
  jobExtrasCompany: number;
  houseTypesCompany: number;
  jobRolesCompany: number;
  orderLines: OrderLine[];

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private httpService: HttpService,
    private companyService: CompanyService,
    private globalService: GlobalService) { }

  // search for jobs by a matching contract name string
  getJobsByAddress(useCache: boolean): Observable<Job[]> {
    if (useCache && this.jobsCompany === this.globalService.getCurrentCompany().id
      && this.jobs && this.jobs.length) {
      return of(this.jobs);
    } else {
      const searchString = '';
      return this._http.get<Job[]>(environment.apiUrl + '/jobs?jobSearchType=JobAddress&searchString=' + searchString,
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobs = res; this.jobsCompany = this.globalService.getCurrentCompany().id;

            this.jobs.forEach(job => {
              job.jobAddressString = this.globalService.getJobString(job);
            });
          }),
          catchError(this.handleError));
    }
  }

  // search for jobs by a matching contract name string
  getTemplateJobs(): Observable<Job[]> {
    return this._http.get<Job[]>(environment.apiUrl + '/jobs?jobSearchType=Templates',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getMyJobs() {
    return this._http.get<Job[]>(environment.apiUrl + '/jobs?jobSearchType=ContractName&userId='
      + this.authService.getCurrentUserId(), this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobsByAddressWithExtras(useCache: boolean): Observable<Job[]> {
    return forkJoin(
      [this.getJobsByAddress(useCache),
      this.getAllJobExtras(useCache),
      this.companyService.getCompanyActivities(true)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getMyJobsWithExtras(): Observable<Job[]> {
    return forkJoin(
      [this.getMyJobs(),
      this.companyService.getCompanyActivities(true),
      this.getAllJobExtras(true)
      ])
      .pipe(map(
        ([jobs]) => {
          return jobs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  // extra details for hold flag etc
  getAllJobExtras(useCache: boolean): Observable<JobExtra[]> {
    if (useCache && this.jobExtrasCompany === this.globalService.getCurrentCompany().id
      && this.jobExtras && this.jobExtras.length) {
      return of(this.jobExtras);
    } else {
      return this._http.get<JobExtra[]>(environment.apiUrl + '/job-extras',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.jobExtras = res; this.jobExtrasCompany = this.globalService.getCurrentCompany().id;
          }),
          catchError(this.handleError));
    }
  }

  getJobExtras(jobId: number): Observable<JobExtra> {
    return this._http.get<JobExtra>(environment.apiUrl + '/job-extras/' + jobId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getOrderLines(jobId: number): Observable<OrderLine[]> {
    let url = environment.apiUrl + '/job/' + jobId + '/order-lines';
    return this._http.get<OrderLine[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
