import { AuthService } from './../../services/auth.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';

import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { VendorService } from '../../services/felixApi/vendor.service';
import { GridService } from '../../services/grid.service';
import { Vendor } from '../../dtos/vendor';
import DataGrid from 'devextreme/ui/data_grid';
import { VendorGroup } from '../../dtos/vendor-group';
import { Invoice } from '../../dtos/invoice';

DataGrid.defaultOptions({
  options: {
    scrolling: {
      legacyMode: true
    }
  }
});

@Component({
  selector: 'js-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  loading = true;
  popupVisible = false;
  value: any[] = [];
  vendors: Vendor[] = [];
  isIPad = false;
  isPhone = false;
  canEdit = false;
  vendorGroups: VendorGroup[];

  overwriteOrderWithCallUpVendorTypes = [
    { id: null, description: 'Blank Out' },
    { id: 1, description: 'Change Vendor' },
    { id: 2, description: 'Show Original' }
  ];
  invoices: Invoice[] = [];

  constructor(
    private vendorService: VendorService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if (this.authService.isAdminOrSuper()
      || this.authService.getSelectionsPermissions('OptionLists') === 'Admin'
      || this.authService.getSelectionsPermissions('Estimating') === 'Admin') {
      this.canEdit = true;
    }
    this.setJobStringMaxWidth();
    this.subscribeToInnerHeightWidth();
    this.loadData();
  }

  subscribeToInnerHeightWidth() {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(
        () => {
          this.loading = true;
          setTimeout(() => {
            this.setJobStringMaxWidth();
            this.loading = false;
          }, 500); // wait for iPhone and grid
        }
      )
    );
  }

  setJobStringMaxWidth() {
    this.isIPad = this.globalService.innerWidth < 1380 ? true : false;
    this.isPhone = this.globalService.innerWidth < 600 ? true : false;
  }

  refresh() {
    // this.informationTypeService.clearCaches();
    this.grid.instance.refresh();
  }

  loadData() {
    if (!this.isIPad) {
      this.subscriptions.push(
        this.vendorService.getInvoices()
          .subscribe({
            next: (invoices) => {
              this.invoices = invoices;
              this.loading = false;
              this.setUpDataSource();
            },
            error: () => {
              this.loading = false;
              this.setUpDataSource();
            }})
      );
    } else {
      this.loading = false;
      this.setUpDataSource();
    }
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.vendorService.getVendorsWithPayables(false, false).subscribe({ next: (res) => {
              this.vendors = res;
              this.vendorGroups = this.vendorService.vendorGroups;

              res.forEach(vendor => {
                vendor.lastInvoice = this.invoices.find(i => i.vendorId === vendor.id)?.invoiceDate;
              });
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }}))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.vendorService.addVendor(values).subscribe({ next: (res) => {
              this.vendors.push(res);
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }}))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.vendorService.updateVendor(encodeURIComponent(key), values).subscribe({ next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }}))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.vendorService.deleteVendor(encodeURIComponent(key)).subscribe({ next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }}))
        );
      }
    });
  }

  onInitNewRow = (e) => {
    e.data.isActive = true;
    e.data.canAcceptInvoices = true;
    e.data.isCallUpShowSupervisor = true;
    e.data.canAnyVendorInvoice = false;
    e.data.markOrdersSentWhenRaised = false;
    e.data.isActive = true;
    e.data.canAttachOrdersToCallUps = true;
  }

  onToolbarPreparing(e) {
    this.gridService.addRefreshBtnToToolbar(e, 4, 3);

    e.toolbarOptions.items.unshift({
      location: 'before',
      widget: 'dxButton',
      options: {
        width: 140,
        text: 'Load From Excel',
        onClick: this.loadFromExcel.bind(this)
      }
    });
  }

  loadFromExcel() {
    this.popupVisible = true;
  }

  uploadClick() {
    // load the file
    if (this.value && this.value.length) {
      this.loading = true;
      this.popupVisible = false;
      const formData: FormData = new FormData();
      formData.append('image', this.value[0], this.value[0].name);
      this.dataSource = null;

      this.subscriptions.push(
        this.vendorService.postUploadtemplate(formData).subscribe({ next: () => {
          this.loading = false;
          this.vendorService.vendors = []; // reset
          this.value = [];
          this.setUpDataSource();
        }, error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
          this.vendorService.vendors = []; // reset
          this.value = [];
          this.setUpDataSource();
        }})
      );
    }
  }

  ngOnDestroy() {
    this.vendorService.vendors = null;

    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data) {
      return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  calculateHasWhiteCard = (rowData): boolean => {
    if (rowData?.whiteCardExpiry) {
      return true;
    } else {
      return false;
    }
  }

  setWhiteCardExpiry(rowData, value) {
    rowData.whiteCardExpiry = value ? new Date() : null;
  }
}
