<div class="recipeLinesBorder">
  <dx-data-grid #recipeLineGrid id="recipeLineGrid" [dataSource]="dataSource" width="100%"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onRowInserted)="$event.component.navigateToRow($event.key)"
    (onEditorPreparing)="onEditorPreparing($event)" (onCellPrepared)="onCellPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)" (onEditingStart)='onEditingStart($event)'
    (onInitNewRow)='onInitNewRow($event)' (onExporting)="onExporting($event)"
    [ngStyle]="{'max-height': 'calc(' + gridMaxHeight + 'px)'}"
    (onExporting)="gridService.onExporting($event, 'Recipe-Lines')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxo-editing [mode]="editMode" [refreshMode]="refreshMode" [useIcons]="true" [allowDeleting]="recipesWrite"
      [allowAdding]="recipesWrite" [allowUpdating]="recipesWrite">
    </dxo-editing>

    <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus"
      enterKeyDirection="column"></dxo-keyboard-navigation>

    <dxi-column *ngIf="isGrouped" dataField="masterGroupCostCentre" dataType="string" caption="Cost Centre"
      [groupIndex]="0" groupCellTemplate="groupCellTemplate" allowEditing="false">
    </dxi-column>

    <dxi-column *ngIf="isGrouped" dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [groupIndex]="1"
      groupCellTemplate="groupCellTemplate" allowEditing="false">
    </dxi-column>

    <dxi-column *ngIf="!isGrouped" dataField="masterGroupCostCentre" dataType="string" caption="Group" [width]="150"
      allowEditing="false" [calculateDisplayValue]="calculateGrouptitle" [calculateSortValue]="calcGroupSortValue">
    </dxi-column>

    <dxi-column *ngIf="!isGrouped" dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [width]="150"
      allowEditing="false" [calculateDisplayValue]="calculateSubGrouptitle"
      [calculateSortValue]="calculateSubGroupSortValue">
    </dxi-column>

    <dxi-column dataField="recipeItemId" dataType="number" [visible]='false'> </dxi-column>

    <dxi-column dataField="recipeCode" dataType="string" caption="Item/Recipe" [width]="110"
      editCellTemplate="codeDropDown" [setCellValue]="setRecipeCellValue" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [minWidth]="100" cellTemplate="wrapTemplate">
    </dxi-column>

    <dxi-column dataField="unitOfMeasureId" dataType="string" caption="Units" [width]="80" [hidingPriority]="5"
      [editorOptions]="{ wrapItemText: true }">
      <dxo-lookup [dataSource]="unitOfMeasures" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="quantityString" caption="Measured Qty" dataType="string" [width]="quantityStringWidth"
      editCellTemplate="editQuantityCellTemplate" [setCellValue]="setQtyCellValue">
    </dxi-column>

    <dxi-column dataField="quantity" caption="Qty" dataType="number" [width]="80" allowEditing="false"
      format="#,###.####">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column caption="Total" [calculateCellValue]="calculateLineTotal" dataType="number" [width]="100"
      [format]="{ type: 'fixedPoint', precision: 2 }" allowEditing="false">
    </dxi-column>

    <dxi-column dataField="marginOverridePercent" caption='Mark-Up Override%' dataType="number" [width]="80"
      [format]="{ type: 'fixedPoint', precision: 2 }" alignment="center" [hidingPriority]="4">
    </dxi-column>

    <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="90" [hidingPriority]="3">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isToBeChecked" caption="To Be Checked" dataType="boolean" [width]="75" [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="comment" dataType="string" [width]="135" [hidingPriority]="1"> </dxi-column>

    <dxi-column dataField="modifiedDate" dataType="date" format="d/M/yy" [visible]="false" [allowEditing]="false"
      alignment="center" [width]="90">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" dataType="number" caption="Modified By" [visible]="false"
      [allowEditing]="false" [width]="120">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>


    <dxi-column type="buttons" [width]="120">
      <dxi-button *ngIf="recipesWrite" name="edit"></dxi-button>
      <dxi-button *ngIf="recipesWrite" name="delete"></dxi-button>
      <dxi-button *ngIf="recipesWrite" hint="explode" icon="share" [visible]="isExplodeIconVisible"
        [onClick]="explodeAsk"></dxi-button>
      <dxi-button hint="open recipe" icon="folder" [visible]="isOpenIconVisible" [onClick]="openRecipe">
      </dxi-button>
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'wrapTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="cellInfo.value"
        displayExpr="recipeCode" valueExpr="recipeCode" contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="recipeDataActiveOnly" [remoteOperations]="false" height="500px"
            [showBorders]="true" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [wordWrapEnabled]="true"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
            (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-selection mode="single"></dxo-selection>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

            <dxi-column dataField="description" cellTemplate="wrapTemplate" [minWidth]="400"></dxi-column>

            <dxi-column dataField="comment" cellTemplate="wrapTemplate"></dxi-column>

            <dxi-column dataField="rate" dataType="number" [format]="{ type: 'fixedPoint', precision: 2 }"
              [width]="100">
            </dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getGroupTitle(data) }}
            </div>

            <div *dxTemplate="let cellInfo of 'wrapTemplate'">
              <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let cellInfo of 'editQuantityCellTemplate'">
      <div *ngIf="!hasSizes">
        <dx-text-box [(value)]="cellInfo.value" (onValueChanged)="setEditedQtyValue($event, cellInfo)"></dx-text-box>
      </div>

      <div *ngIf="hasSizes">
        <dx-text-box id="qtyEditCell" [(value)]="cellInfo.value" [disabled]="true"></dx-text-box>
        <dx-button id="qtyEditButton" icon="edit" (onClick)="editLengths(cellInfo)"></dx-button>
      </div>
    </div>
  </dx-data-grid>
</div>

<dx-popup class="popup" [width]="400" [height]="210" [showTitle]="true" title="Explode Recipe?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">
    <div><span *ngIf="!quantityForExplode" class="red">Note: Quantity is zero !!</span></div>
    <br>
    <div>
      <dx-button id="explodeLineButton2" text="Explode - View Only" type="success"
        (onClick)="openRecipe2()"></dx-button>
      <br>
      <dx-button id="explodeLineButton2" text="Explode" type="danger" (onClick)="explode()"></dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="400" [height]="250" [showTitle]="true" [title]="explodeTitle" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="explodeRecipePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="explodeLineButton2" text="Explode - View Only" type="success" (onClick)="explodeRecipeInMemory()">
    </dx-button>
    <br>
    <dx-button id="explodeLineButton2" text="Explode All Lines" type="danger" (onClick)="explodeRecipeGo()"></dx-button>
    <br>
    <dx-check-box text="Ignore empty recipes" [(value)]="ignoreEmptyRecipes" [rtlEnabled]="true"
      class="mt-2"></dx-check-box>

    <div class="mt-2">Note: recipes marked To Be Checked will not be exploded</div>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="300" [height]="475" [showTitle]="true" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="lengthPopupVisible" (onHiding)="hidingLengthPopup($event)"
  titleTemplate="title">

  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="lengthsDataGrid" class="m-auto" [dataSource]="lengthsDataSource" [height]="340" [width]="250"
      [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="false" [wordWrapEnabled]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="repaint">
      </dxo-editing>

      <dxi-column dataField="quantity">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="length">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
    <dx-button id="button" class="mt-1 ml-1" text="Close" type="default" (onClick)="closeLengthPopup()"></dx-button>
  </div>

  <div *dxTemplate="let data of 'title'">
    <div class="qtyPopuptitle">{{recipeLineDescription}}</div>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="350" [height]="200" [showTitle]="true" title="Re-Calc Selling Price"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="recalcSellingPricePopupVisible">
  <div *dxTemplate="let data of 'content'">

    <div class="dx-field">
      <dx-date-box [(value)]="reCostingDate" type="date" [calendarOptions]="{ showTodayButton: true }" [width]="120">
      </dx-date-box>
    </div>

    <div class="dx-field">
      <dx-check-box [(value)]="updateOptionLists" text="Update any associated Option Lists?">
      </dx-check-box>
    </div>

    <dx-button text="Go" type="success" (onClick)="recalcSellingPriceGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
