import { NotificationService } from './../../services/notification.service';
import { District } from './../../dtos/district';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { PriceFileItem } from './../../dtos/price-file-item';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PriceFileService } from '../../services/felixApi/price-file.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-calc-est-extra',
  templateUrl: './calc-est-extra.component.html',
  styleUrls: ['./calc-est-extra.component.scss']
})
export class CalcEstExtraComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  selectedCostCentres: number[] = [];
  gridHeight: number;
  gridWidth: number;
  costCentres: PriceFileItem[];
  loading = false;
  districtId: number;
  effectiveDate: Date;
  districts: District[];
  dataSource: CustomStore;

  constructor(
    private priceFileService: PriceFileService,
    private maintenanceService: MaintenanceService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.gridHeight = window.innerHeight - 350;
    this.gridWidth = window.innerWidth > 800 ? 800 : window.innerWidth - 50;

    this.costCentres = this.priceFileService.priceFileItemGroups.filter(i => !i.priceFileItemParentId);
    this.districts = this.maintenanceService.districts;

    this.dataSource = new CustomStore({
      key: 'id',
      load: () => this.costCentres
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  runUpdate() {
    this.loading = true;
    this.subscriptions.push(
      this.priceFileService.calcEstExtraForDearest(this.districtId, formatDate(this.effectiveDate, 'yyyy-MM-dd'), { ids: this.selectedCostCentres })
        .subscribe({
          next: () => {
            this.activeModal.close();
          },
          error: (err) => {
            this.notiService.showError(err);
            this.loading = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }
}
