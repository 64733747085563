<div class="page recipesHeight">

  <dx-button *ngIf="!loading" id="advancedSearch" text="Advanced Search" type="default" (onClick)="advancedSearch()">
  </dx-button>
  <dx-text-box id="globalSearchClass" [showClearButton]="true" placeholder="Global Search..." valueChangeEvent="keyup"
    (onValueChanged)="globalSearchChanged($event)" [(value)]="globalSearchText">
  </dx-text-box>
  <dx-button id="class-button" hint="Tool box" icon="toolbox" type="default" (onClick)="openToolBox()">
  </dx-button>
  <dx-button id="class-button" hint="Refresh" icon="refresh" type="default" (onClick)="refreshRecipes(false)">
  </dx-button>

  <dx-check-box id="isCostUseExtraClass" [(value)]="costsIncEstimatingExtra" text="Costs include estimating extra"
    rtlEnabled="true" (onValueChanged)="onIncEstimatingExtraChanged($event)"> </dx-check-box>

  <dx-date-box [value]="estimatingService.costingDate" type="date" class='costingDate m-r-10'
    (onValueChanged)="onCostingDateChanged($event)" [calendarOptions]="{ showTodayButton: true }">
  </dx-date-box>

  <i (click)="toggleHelp()" class="material-icons md-18 help-icon">help</i>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-box *ngIf="!loading" direction="row" class="p-t-10 full-width">
    <dxi-item class="recipesClass" [ratio]="0" [baseSize]="360">
      <dx-tree-list #treeList id="tree-list" [dataSource]="treeListDataSource" keyExpr="id" class="gridHeight"
        parentIdExpr="recipeParentId" remoteOperations="false" [showRowLines]="false" [showColumnLines]="false"
        [showBorders]="true" [columnAutoWidth]="true" [rootValue]=null [expandedRowKeys]="[0]" [wordWrapEnabled]="true"
        [(selectedRowKeys)]="selectedRowKeys" (onEditorPreparing)="editorPreparing($event)"
        (onInitNewRow)="initNewRow($event)" (onCellPrepared)="cellPrepared($event)">

        <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>

        <dxo-row-dragging [onReorder]="onReorder" [allowDropInsideItem]="true" [allowReordering]="true" [onAdd]="onAdd"
          [data]="1" group="recipesGroup" [showDragIcons]="false"></dxo-row-dragging>

        <dxo-editing mode="row" [allowAdding]="recipesWrite" [allowUpdating]="recipesWrite"
          [allowDeleting]="recipesWrite" [useIcons]="true" refreshMode="reshape"></dxo-editing>

        <dxo-selection mode="single"></dxo-selection>

        <dxo-scrolling mode="standard"></dxo-scrolling>

        <dxo-paging [enabled]="false">
        </dxo-paging>

        <!-- <dxo-pager [allowedPageSizes]="[10, 20, 50]" [showPageSizeSelector]="true"
                    [showNavigationButtons]="true">
                </dxo-pager> -->

        <dxi-column dataField="description" caption="Class">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc"></dxi-column>

        <dxi-column dataField="isAdminOnly" caption="Admin Only" [visible]="recipesAdmin" [width]="55"></dxi-column>

        <dxi-column type="buttons" [hidingPriority]="2">
          <dxi-button *ngIf="recipesWrite" hint="Add Child" icon="add" [onClick]="addClass"></dxi-button>
          <dxi-button *ngIf="recipesWrite" name="edit"></dxi-button>
          <dxi-button *ngIf="recipesWrite" name="delete"></dxi-button>
        </dxi-column>

      </dx-tree-list>
    </dxi-item>
    <dxi-item class="recipesClass" [ratio]="1">
      <js-recipes [recipeParentId]="selectedRowKeys[0]" [refreshRecipeComponent]="refreshRecipeComponent"
        (refreshFromFind)="refreshFromFind($event)"></js-recipes>
    </dxi-item>
  </dx-box>

  <dx-popup class="popup" [width]="370" [height]="120" [showTitle]="true" title="Tips" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="helpPopupVisible">
    <div *dxTemplate="let data of 'content'">
      You can click and drag classes to reorder.
    </div>
  </dx-popup>

</div>

<!-- <js-recipe-toolbox *ngIf="showToolbox" (toolboxClose)="toolboxClose($event)"></js-recipe-toolbox> -->

<!-- loading spinner - DevExtreme -->
<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="globalVariablesService.classesLoadingSpinnerVisible"
  [showIndicator]="true" [showPane]="true" [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
