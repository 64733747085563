import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';

import { GlobalService } from '../../../services/global.service';
import { MaintenanceService } from '../../../services/felixApi/maintenance.service';
import { GridService } from '../../../services/grid.service';
import { NotificationService } from '../../../services/notification.service';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import DataGrid from 'devextreme/ui/data_grid';

DataGrid.defaultOptions({
  options: {
    scrolling: {
      legacyMode: true
    }
  }
});

@Component({
  selector: 'js-cost-centre-footer',
  templateUrl: './cost-centre-footer.component.html',
  styleUrls: ['./cost-centre-footer.component.scss']
})
export class CostCentreFooterComponent implements OnInit, OnDestroy {

  @ViewChild('editor') htmlEditor: DxHtmlEditorComponent;

  @Output() closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  savinghtml = true;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  showHtmlPopup: boolean;
  footerHtml: string;
  htmlTitle: string;
  currentRecordId: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    protected gridService: GridService
  ) { }

  ngOnInit(): void {
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getCostCentreFooters(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addCostCentreFooter(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateCostCentreFooter(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteCostCentreFooter(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  onHiding() {
    this.closePopup.emit(true);
  }

  openHtmlFooter(e) {
    if (e.row.isNewRow) {
      this.notiService.showInfo('Save record before adding claim lines');
    } else {
      this.currentRecordId = e.row.data.id;
      this.showHtmlPopup = true;
      this.savinghtml = false;
      this.htmlTitle = e.data.description + ' footer';
      this.footerHtml = e.data.footerHtml;
      if (this.footerHtml) {
        setTimeout(() => {
          this.htmlEditor.instance.option('value', this.footerHtml);
        }, 1);
      } else {
        this.htmlEditor.instance.option('value', '');
      }
    }
  }

  valueChange(value) {
    this.footerHtml = value;
  }

  saveHtmlFooter() {
    const dataRecord = {
      // fix html for new lines
      footerHtml: this.footerHtml.replace(new RegExp('<p', 'g'), '<div').replace(new RegExp('p>', 'g'), 'div>')
    };

    this.savinghtml = true;
    this.subscriptions.push(
      this.maintenanceService.updateCostCentreFooter(this.currentRecordId, dataRecord).subscribe({
        next: () => {
          this.savinghtml = false;
          this.showHtmlPopup = false;
          this.setUpDataSource();
        }, error: (err) => {
          this.savinghtml = false;
          this.notiService.notify(err);
        }
      })
    );
  }
}
