import { PriceFileItem } from './price-file-item';
import { PriceFileVendor } from './price-file-vendor';
import { PriceFileRate } from './price-file-rate';
import { PriceFileBook } from './price-file-book';

export class PriceFileItemVendorRate {
    public id: PriceFileItemVendorRateId;
    // group
    public priceFileGroupId: number;
    public groupOrderNumber: number;
    public priceFileItemParentId: number;
    public subGroupOrderNumber: number;

    // item
    public priceFileItemId: number;
    public priceFileItemTypeId: number;
    public priceFileCode: string;
    public description: string;
    public unitOfMeasureId: number;
    public hasSizes: boolean;
    public centralCompanyPriceFileItemId: number;
    public isActive: boolean;
    public comment: string;

    // book fields
    public priceFileBookId: number;
    public districtId: number;
    public preferredVendorId: number;

    // vendor fields
    public priceFileVendorId: number;
    public vendorId: number;
    public vendorName: string;
    public productCode: string;
    public qtySizeControlId: number;
    public vendorItemDescription: string;

    // rate fields
    public priceFileRateId: number;
    public effectiveDate: Date;
    public rate: number;
    public expiryDate: Date;
    public estimatingExtra: number;
    public modifiedDate: Date;
    public modifiedUserId: number;

    constructor(priceFileGroupId: number, groupOrderNumber: number, priceFileSubGroupId: number, subGroupOrderNumber: number,
        priceFileItem: PriceFileItem, priceFileBook: PriceFileBook, priceFileVendor: PriceFileVendor, priceFileRate: PriceFileRate) {

        this.id = {
            priceFileGroupId: priceFileGroupId,
            priceFileItemParentId: priceFileSubGroupId,
            priceFileItemId: priceFileItem ? priceFileItem.id : null,
            priceFileBookId: priceFileBook ? priceFileBook.id : null,
            priceFileVendorId: priceFileVendor ? priceFileVendor.id : null,
            priceFileRateId: priceFileRate ? priceFileRate.id : null
        };

        this.priceFileGroupId = priceFileGroupId;
        this.groupOrderNumber = groupOrderNumber;
        this.priceFileItemParentId = priceFileSubGroupId;
        this.subGroupOrderNumber = subGroupOrderNumber;

        this.priceFileItemId = priceFileItem ? priceFileItem.id : null;
        this.priceFileItemTypeId = priceFileItem ? priceFileItem.priceFileItemTypeId : null;
        this.priceFileCode = priceFileItem ? priceFileItem.priceFileCode : null;
        this.description = priceFileItem ? priceFileItem.description : null;
        this.unitOfMeasureId = priceFileItem ? priceFileItem.unitOfMeasureId : null;
        this.hasSizes = priceFileItem ? priceFileItem.hasSizes : false;
        this.centralCompanyPriceFileItemId = priceFileItem ? priceFileItem.centralCompanyPriceFileItemId : null;
        this.isActive = priceFileItem ? priceFileItem.isActive : true;
        this.comment = priceFileItem ? priceFileItem.comment : null;

        // book fields
        this.districtId = priceFileRate ? priceFileBook.districtId : null;
        this.preferredVendorId = priceFileBook ? priceFileBook.preferredVendorId : null;

        // vendor fields
        this.priceFileVendorId = priceFileVendor ? priceFileVendor.id : null;
        this.vendorId = priceFileVendor ? priceFileVendor.vendorId : null;
        this.productCode = priceFileVendor ? priceFileVendor.productCode : null;
        this.qtySizeControlId = priceFileVendor ? priceFileVendor.qtySizeControlId : null;
        this.vendorItemDescription = priceFileVendor ? priceFileVendor.vendorItemDescription : null;

        // rate fields
        this.priceFileRateId = priceFileRate ? priceFileRate.id : null;
        this.effectiveDate = priceFileRate ? priceFileRate.effectiveDate : null;
        this.rate = priceFileRate ? priceFileRate.rate : null;
        this.expiryDate = priceFileRate ? priceFileRate.expiryDate : null;
        this.estimatingExtra = priceFileRate ? priceFileRate.estimatingExtra : null;
    }
}

export class PriceFileItemVendorRateId {
    public priceFileGroupId: number;
    public priceFileItemParentId: number;
    public priceFileItemId: number;
    public priceFileBookId: number;
    public priceFileVendorId: number;
    public priceFileRateId: number;
}
