import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Recipe } from '../../dtos/recipe';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-upload-recipe-lines',
  templateUrl: './upload-recipe-lines.component.html',
  styleUrls: ['./upload-recipe-lines.component.scss']
})
export class UploadRecipeLinesComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  subscriptions: Subscription[] = [];
  deleteExistingLines = false;
  value: any[] = [];
  loading = false;

  constructor(
    private estimatingService: EstimatingService,
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  uploadClick() {
    // load the file
    if (this.value && this.value.length) {
      this.loading = true;
      const formData: FormData = new FormData();
      formData.append('image', this.value[0], this.value[0].name);

      this.subscriptions.push(
        this.estimatingService.postUploadTakeOff(formData, this.recipe.id, this.deleteExistingLines).subscribe({
          next: () => {
            this.activeModal.close();
          }, error: (err) => {
            this.notiService.notify(err);
            this.value = [];
            this.loading = false;
          }
        })
      );

    }
  }

}
