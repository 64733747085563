<div class="modal-header">
  <h4 class="modal-title" placement="bottom">{{titleText}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-button id="toolbox-button" text="Recalculate Selling Prices" type="default" stylingMode="outlined"
    (onClick)="recostRecipes()"> </dx-button>

  <br><br>

  <dx-button id="toolbox-button" text="Replace Item" type="default" stylingMode="outlined" (onClick)="replaceItem()">
  </dx-button>

  <div *ngIf="!recipeParentId">
    <br>
    <dx-button id="toolbox-button" text="Compare Cost Centres" type="default" stylingMode="outlined"
      (onClick)="recipeCompare()">
    </dx-button>
  </div>

  <div *ngIf="!recipeParentId && recipesAdmin">
    <br>
    <dx-button id="toolbox-button" text="Set Mark-Ups" type="default" stylingMode="outlined"
      (onClick)="setMarkup()">
    </dx-button>
  </div>

  <div *ngIf="!recipeParentId && recipesAdmin">
    <br>
    <dx-button id="toolbox-button" text="Set Active/Not-Active" type="default" stylingMode="outlined"
      (onClick)="setNotActive()">
    </dx-button>
  </div>
</div>

<js-re-calc-selling *ngIf="showCalcSelling" [recipeParentId]="recipeParentId"
  (toolboxClose)="closeToolboxAfter($event)"></js-re-calc-selling>

<js-item-replace *ngIf="showReplaceItem" [recipeParentId]="recipeParentId"
  (toolboxClose)="closeToolboxAfter($event)"></js-item-replace>
