<div class="page">
  <dx-tree-list #treeList id="tree-list" [dataSource]="treeListDataSource" keyExpr="id"
    class="max-w-2100 m-auto grid-height" parentIdExpr="priceFileItemParentId" remoteOperations="false"
    [showRowLines]="false" [showColumnLines]="true" [showBorders]="true" [columnAutoWidth]="true" [rootValue]=null
    [expandedRowKeys]="[0]" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedRowKeys"
    (onEditorPreparing)="editorPreparing($event)" (onInitNewRow)="initNewRow($event)"
    (onEditingStart)="onEditStart($event)" (onCellPrepared)="cellPrepared($event)"
    (onEditCanceling)="onEditCancelling()" (onToolbarPreparing)="onToolbarPreparing($event)">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-row-dragging [onReorder]="onReorder" [allowDropInsideItem]="true" [allowReordering]="true"
      [showDragIcons]="false"></dxo-row-dragging>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="reshape">
    </dxo-editing>

    <dxo-selection mode="single"></dxo-selection>

    <dxo-scrolling mode="standard"></dxo-scrolling>

    <dxi-column dataField="priceFileItemParentId" [visible]="false" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="priceFileItemTypeId" [visible]="false" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="description" caption="Cost Centre / Sub-Group">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc"></dxi-column>

    <dxi-column dataField="priceFileCode" dataType="string" caption="Code" [width]="55">
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" caption="Purchase Orders">
      <dxi-column dataField="costCentreFooterId" dataType="number" caption="Order Footer"
        [editorOptions]="{ showClearButton: true }" [width]="130">
        <dxo-lookup [dataSource]="costCentreFooters" displayExpr="description" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="showSiteManagerOnOrder" caption="Show Site Manager" dataType="boolean" [width]="80">
      </dxi-column>

      <dxi-column dataField="includeAddendum" dataType="boolean" [width]="85">
      </dxi-column>

      <dxi-column dataField="showCostCentreOnOrder" caption="Show Cost Centre Name" dataType="boolean" [width]="100">
      </dxi-column>

      <dxi-column dataField="isHideCostsFromTracking" caption="Hide Costs From Tracking" dataType="boolean"
        [width]="102">
      </dxi-column>

      <dxi-column dataField="callUpDocsTypes" caption="Info Attachment Types" [width]="240" [allowSorting]="false"
        editCellTemplate="tagBoxEditor" [cellTemplate]="cellTemplate"
        [calculateFilterExpression]="calculateFilterExpression">
        <dxo-lookup [dataSource]="callUpDocsTypes" valueExpr="id" displayExpr="description">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="vendorGroupId" caption="Vendor Group for Cash Flow" dataType="number" width="150"
        [editorOptions]="{ showClearButton: true }">
        <dxo-lookup [dataSource]="vendorGroups" valueExpr="id" displayExpr="description">
        </dxo-lookup>
      </dxi-column>
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" dataField="isInvoiceManuallyAuthorised"
      caption="Invoices Manually Authorised" dataType="boolean" width="85">
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" dataField="doNotAllowCosts" dataType="boolean" width="65">
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" dataField="isHideInvoiceFromSupervisor"
      [caption]="calculateHideInvoiceFromSupervisorColumnName()" text dataType="boolean" width="98">
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" dataField="isInvoiceAutoAuthorised"
      caption="Invoices Auto Authorised" dataType="boolean" width="85">
    </dxi-column>

    <dxi-column *ngIf="purchaseOrderSystemActive" dataField="costCentreApprovers" caption="Invoice Approvers"
      [width]="190" [allowSorting]="false" editCellTemplate="userTagBoxEditor" [cellTemplate]="cellTemplate"
      [calculateFilterExpression]="calculateFilterExpression">
      <dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isActive" dataType="boolean" [width]="55">
    </dxi-column>

    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button hint="Add Child" icon="add" [onClick]="addGroup" [visible]="isAddIconVisible"></dxi-button>
    </dxi-column>


    <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
      <dx-tag-box *ngIf="!priceFileItemParentId" [dataSource]="callUpDocsTypes" [value]="cellInfo.value" valueExpr="id"
        displayExpr="description" [showSelectionControls]="true" [maxDisplayedTags]="55" [showMultiTagOnly]="false"
        applyValueMode="useButtons" [searchEnabled]="true" (onValueChanged)="cellInfo.setValue($event.value)"
        (onSelectionChanged)="cellInfo.component.updateDimensions()">
      </dx-tag-box>
    </div>

    <div *dxTemplate="let cellInfo of 'userTagBoxEditor'">
      <dx-tag-box *ngIf="!priceFileItemParentId" [dataSource]="filteredUsers" [value]="cellInfo.value" valueExpr="id"
        displayExpr="fullName" [showSelectionControls]="true" [maxDisplayedTags]="55" [showMultiTagOnly]="false"
        applyValueMode="useButtons" [searchEnabled]="true" (onValueChanged)="cellInfo.setValue($event.value)"
        (onSelectionChanged)="cellInfo.component.updateDimensions()">
      </dx-tag-box>
    </div>
  </dx-tree-list>


  <dx-popup class="popup" [width]="370" [height]="220" [showTitle]="true" title="Tips" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="helpPopupVisible">
    <div *dxTemplate="let data of 'content'">
      You can click and drag groups to reorder.
    </div>
  </dx-popup>

  <!-- loading spinner - DevExtreme -->
  <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true"
    [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
  </dx-load-panel>
</div>

<js-cost-centre-footer *ngIf="showCostCentreFooters" (closePopup)="closeCostCentreFooters()"></js-cost-centre-footer>
