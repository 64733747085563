<js-loading-spinner *ngIf="loading"></js-loading-spinner>

<div class="divHeight">
  <dx-data-grid *ngIf="recipeParentId && !loading" #recipesGrid id="recipesGrid" class="gridHeight"
    [dataSource]="dataSource" [allowColumnResizing]="true" [allowColumnReordering]="false" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onInitNewRow)="initNewRow($event)" (onEditorPreparing)="onEditorPreparing($event)"
    (onCellPrepared)="onRateCellPrepared($event)" (onRowExpanding)="onRowExpanding($event)"
    (onExporting)="gridService.onExporting($event, 'Recipes')">

    <dxo-search-panel [visible]="true" [width]="200" [(text)]="searchText"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-editing mode="row" [allowAdding]="recipesWrite" [allowUpdating]="recipesWrite" [allowDeleting]="recipesWrite"
      [useIcons]="true" refreshMode="full">
    </dxo-editing>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-row-dragging [data]="2" group="recipesGroup" [allowReordering]="true" [showDragIcons]="true"
      [onReorder]="onReorder">
    </dxo-row-dragging>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="recipes-grid"></dxo-state-storing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxi-column dataField="id" dataType="number" [visible]='false'>
    </dxi-column>

    <dxi-column dataField="recipeCode" dataType="string" caption="Code" [width]="100" sortOrder="asc"
      cellTemplate="recipeCodeCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [minWidth]="150">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="unitOfMeasure" dataType="string" caption="Units" [width]="80" [hidingPriority]="8"
      [editorOptions]="{ showClearButton: true, wrapItemText: true }">
      <dxo-lookup [dataSource]="unitOfMeasures" displayExpr="description" valueExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="districtId" dataType="number" caption="Book" [width]="80" [hidingPriority]="7">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="districts" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <!-- <dxi-column dataField="recipeCostingVendorTypeId" dataType="number" caption="Vendor" [width]="80"
      [hidingPriority]="4">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="recipeCostingVendorTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column> -->

    <dxi-column dataField="rate" caption="Cost" dataType="number" [width]="100" format="#,##0.00"
      [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column dataField="scale" caption="Scale Down" dataType="number" [width]="70" format="#,###.####"
      [hidingPriority]="5">
    </dxi-column>

    <dxi-column dataField="companyMarginId" dataType="number" caption="Mark-Up" [width]="100"
      editCellTemplate="marginDropDown" [setCellValue]="setMarginIdCellValue" [hidingPriority]="6">
      <dxo-lookup [dataSource]="companyMargins" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isGSTFree" caption="GST Free?" dataType="boolean" [width]="55" [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="sellingPriceNow" caption="Selling Price Calculated" dataType="number" [width]="100"
      format="#,###" [allowEditing]="false" [calculateCellValue]="calculateSellingPriceNow">
    </dxi-column>

    <dxi-column dataField="sellingPrice" caption="Selling Price Stored" dataType="number" [width]="100" format="#,###"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="sellingPriceLastUpdated" dataType="date" caption="As at" [width]="85" alignment="center"
      [allowEditing]="false" [hidingPriority]="3" cellTemplate="dateCellTemplate">
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" [width]="60" [hidingPriority]="1">
    </dxi-column>

    <dxi-column dataField="isLocked" caption="Locked" dataType="boolean" [width]="60" [hidingPriority]="9">
    </dxi-column>

    <dxi-column dataField="modifiedDate" dataType="date" format="d/M/yy" [visible]="false" [allowEditing]="false"
      alignment="center" [width]="80">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" dataType="number" caption="Modified By" [visible]="false"
      [allowEditing]="false" [width]="120">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>


    <dxi-column type="buttons" [width]="160">
      <dxi-button *ngIf="recipesWrite" name="edit"></dxi-button>
      <dxi-button *ngIf="recipesWrite" hint="delete" icon="trash" [onClick]="deleteAsk"></dxi-button>
      <dxi-button hint="copy recipe" icon="copy" [onClick]="copyRecipe"> </dxi-button>
      <dxi-button hint="find where used" icon="find" [onClick]="findRecipe"> </dxi-button>
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'marginDropDown'">
      <dx-drop-down-box [dropDownOptions]="marginDropDownOptions" [dataSource]="companyMargins"
        [(value)]="cellInfo.value" [showClearButton]="true" displayExpr="description" valueExpr="id"
        contentTemplate="contentTemplate" (onValueChanged)="onMarginValueChanged($event, cellInfo)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="companyMargins" [remoteOperations]="true" height="500px"
            [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true" [filterRow]="{ visible: true }"
            [scrolling]="{ mode: 'infinite' }" (onSelectionChanged)="onSelectionChanged(cellInfo, e, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>

            <dxi-column dataField="id" [width]="100" [visible]="false"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="margin" [width]="80" [format]="{ type: 'fixedPoint', precision: 1 }"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <dxo-master-detail [enabled]="true" template="masterDetail"
      (onRowExpanding)="onRowExpanding($event)"></dxo-master-detail>

    <div *dxTemplate="let masterDetailItem of 'masterDetail'">
      <js-recipe-lines [recipe]=masterDetailItem.data (refresh)="refresh(masterDetailItem.data.id)"></js-recipe-lines>
    </div>

    <div *dxTemplate="let data of 'recipeCodeCellTemplate'">
      <dx-button id="recipe-code-button" (onClick)="onRecipeCodeClick(data)" stylingMode="text" type="normal">
        <p><b>{{data.value}}</b></p>
      </dx-button>
    </div>

    <div *dxTemplate="let data of 'dateCellTemplate'">
      {{data.value | date:'d-MMM-yy'}}
    </div>

  </dx-data-grid>
</div>

<dx-popup class="popup" [width]="400" [height]="uploadPopupHeight" [showTitle]="true" title="Load from Excel"
  [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'">

    <div class="dx-field">
      <div class="dx-field-label">Load:</div>
      <div class="dx-field-value">
        <dx-select-box [(ngModel)]="excelLoadTypeId" [dataSource]="excelLoadTypes" valueExpr="id"
          displayExpr="description" placeholder="Default" (onSelectionChanged)="onLoadTypeChanged($event)">
        </dx-select-box>
      </div>
    </div>

    <div *ngIf="excelLoadTypeId === 1" class="dx-field">
      NOTE: Recipes will be loaded into this class
    </div>

    <div *ngIf="excelLoadTypeId === 2">
      <dx-check-box [(ngModel)]="skipMissingRecipes" text="Skip missing recipes" [rtlEnabled]="true"> </dx-check-box>
    </div>

    <div *ngIf="excelLoadTypeId === 2">
      <dx-check-box [(ngModel)]="loadToCurrentClassOnly" text="Load to current class only" [rtlEnabled]="true">
      </dx-check-box>
    </div>

    <div *ngIf="excelLoadTypeId === 2">
      <dx-check-box [(ngModel)]="ignoreZeroQuantityItems" text="Ignore zero quantity items" [rtlEnabled]="true">
      </dx-check-box>
    </div>

    <div *ngIf="excelLoadTypeId === 2">
      NOTE: Recipes with existing lines will be ignored
    </div>

    <div id="fileuploader-container">
      <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
        [(value)]="files" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
      </dx-file-uploader>
    </div>

    <dx-button *ngIf="excelLoadTypeId === 1 || excelLoadTypeId === 2" id="sample-button" text="Sample" type="default"
      stylingMode="outlined" (onClick)="downloadSample()">
    </dx-button>
    <dx-button id="load-button" text="Load" type="success" (onClick)="uploadClick()" [disabled]="!files.length">
    </dx-button>
  </div>
</dx-popup>

<!-- delete recipe -->
<dx-popup class="popup" [width]="250" [height]="160" [showTitle]="true" [title]="recipeToDeleteDesc"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="deletePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="dx-field">
      <dx-check-box [(value)]="deleteLines" text="Delete lines?">
      </dx-check-box>
    </div>
    <dx-button id="button" text="Delete" type="danger" (onClick)="deleteGo()"></dx-button>
  </div>
</dx-popup>

<!-- <js-recipe-toolbox *ngIf="showToolbox" [recipeParentId]="recipeParentId" (toolboxClose)="toolboxClose($event)">
</js-recipe-toolbox> -->
