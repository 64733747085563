import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { User } from '../../dtos/user';
import { HttpService } from '../http.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {
    users: User[] = [];

    constructor(
        private http: HttpClient,
        private httpService: HttpService) { }

    getCurrCompUsers(userTypeId: number = null): Observable<User[]> {
        let url = environment.apiUrl + '/companies/users';
        if (userTypeId !== null) {
            url += '?userTypeId';
        }

        return this.http.get<User[]>(url, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.users = res;
          }),
          catchError(this.handleError));
    }

    getUser(id: number): Observable<User> {
        return this.http.get<User>(environment.apiUrl + '/users/' + id + '?includeUserType=true',
        this.httpService.getHttpOptions()).pipe(
            catchError(this.handleError));
    }

    getUserCompany(id: number): Observable<User> {
        return this.http.get<User>(environment.apiUrl + '/companies/users/' + id,
        this.httpService.getHttpOptions()).pipe(
            catchError(this.handleError));
    }

    getUserByEmail(email: string) {
        return this.http.get<User>(environment.apiUrl + '/users/' + email, this.httpService.getHttpOptions()).pipe(
            catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        console.log(JSON.stringify(err));
        return observableThrowError(err);
    }
}
