import { UserService } from './user.service';
import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileVendor } from '../../dtos/price-file-vendor';
import { PriceFileRate } from '../../dtos/price-file-rate';
import { PriceFileItemVendorRate } from '../../dtos/price-file-item-vendor-rate';
import { PDFReports } from '../../dtos/pdf-report';
import { District } from '../../dtos/district';
import { MaintenanceService } from './maintenance.service';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { CostCentreFooter } from '../../dtos/cost-centre-footer';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { RecipePriceFileItem } from '../../dtos/recipe-pricefile-item';
import { VendorService } from './vendor.service';

@Injectable({
  providedIn: 'root'
})
export class PriceFileService {
  loadingCostCentres: boolean;
  gettingPriceFileItems = false;
  gettingPriceFileVendors: boolean;
  gettingPriceFileRates: boolean;
  priceFileItems: PriceFileItem[];
  cachCompanyPriceFileItems: string;
  priceFileItemVendorRates: PriceFileItemVendorRate[];
  cachCompanyPriceFileItemVendorRates: string;
  priceFileItemGroups: PriceFileItem[];
  cachCompanyPriceFileItemGroups: string;
  recipeAndItems: RecipePriceFileItem[];
  costsIncEstimatingExtra = true;


  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private userService: UserService,
    private vendorService: VendorService,
  ) { }


  getPriceFileItemGroups(useCache: boolean, includeCostCentreData = false): Observable<PriceFileItem[]> {
    if (useCache && this.priceFileItemGroups && this.priceFileItemGroups.length
      && this.cachCompanyPriceFileItemGroups === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemGroups);
    } else {
      let url = environment.apiUrl + '/price-file-items';

      url += '?priceFileItemTypeId=' + PriceFileItemTypeEnum.Group;

      url += '&includeCostCentreData=' + includeCostCentreData;

      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemGroups = res; this.cachCompanyPriceFileItemGroups = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileItems(useCache: boolean): Observable<PriceFileItem[]> {
    if (useCache && this.priceFileItems && this.priceFileItems.length
      && this.cachCompanyPriceFileItems === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItems);
    } else {
      let url = environment.apiUrl + '/price-file-items';

      url += '?priceFileItemTypeId=' + PriceFileItemTypeEnum.Item;


      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItems = res; this.cachCompanyPriceFileItems = this.globalService.getCurrentCompanyId();

          this.priceFileItems.forEach(priceFileItem => {
            const itemGroup = this.priceFileItemGroups.find(i => i.id === priceFileItem.priceFileItemParentId);
            const itemGroupDesc = itemGroup?.priceFileCode ?
              itemGroup.priceFileCode + ' - ' + itemGroup.description : itemGroup?.description;
            priceFileItem.itemGroup = ('00000' + itemGroup?.orderNumber.toString()).slice(-6) + ';' + itemGroupDesc;

            const costCentre = this.priceFileItemGroups.find(i => i.id === itemGroup?.priceFileItemParentId);
            const costCentreDesc = costCentre?.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre?.description
              : costCentre?.description;
            priceFileItem.costCentre = 'I' + ('00000' + costCentre?.orderNumber.toString()).slice(-6) + ';' + costCentreDesc;

            priceFileItem.isActive = priceFileItem.isActive && itemGroup?.isActive && costCentre?.isActive;
          });
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileGroupsData(useCache: boolean): Observable<CostCentreFooter[]> {
    return forkJoin(
      [
        this.maintenanceService.getCostCentreFooters(useCache),
        this.maintenanceService.getCallUpDocsTypes(useCache),
        this.companyService.getCompanyRoles(useCache),
        this.userService.getCurrCompUsers(),
        this.vendorService.getVendorGroups(useCache)
      ]
    )
      .pipe(map(
        ([costCentreFooters]) => {
          return costCentreFooters;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPriceFileGroupsWithData(): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.getPriceFileItemGroups(false, true),
        this.maintenanceService.getCostCentreCallUpDocs(false),
        this.maintenanceService.getCostCentreApprovers(false)
      ]
    )
      .pipe(map(
        ([costCentres]) => {
          // console.log(this.maintenanceService.costCentreCallUpDocs);
          costCentres.forEach(priceFileItem => {
            priceFileItem.callUpDocsTypes = [];
            this.maintenanceService.costCentreCallUpDocs.filter(i => i.costCentreId === priceFileItem.id).forEach(docsType => {
              priceFileItem.callUpDocsTypes.push(docsType.callUpDocsTypeId);
            });

            priceFileItem.costCentreApprovers = [];
            this.maintenanceService.costCentreApprovers.filter(i => i.costCentreId === priceFileItem.id).forEach(approver => {
              priceFileItem.costCentreApprovers.push(approver.userId);
            });
          });
          return costCentres;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  // getRecipeData(useCache: boolean): Observable<PriceFileItem[]> {
  //   return forkJoin(
  //     [
  //       this.getPriceFileItemGroups(useCache),
  //       this.maintenanceService.getPhases(useCache),
  //       this.maintenanceService.getDistricts(useCache),
  //       this.maintenanceService.getUnitOfMeasures(useCache),
  //       this.maintenanceService.getQtySizeControls(useCache),
  //       this.companyService.getCurrentGST()
  //     ]
  //   )
  //     .pipe(map(
  //       ([priceFileItems]) => {
  //         return priceFileItems;
  //       }, (err) => {
  //         return this.globalService.returnError(err);
  //       }
  //     ));
  // }

  getRecipeLineData(): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.getPriceFileItemGroups(true),
        this.maintenanceService.getPhases(true),
        this.maintenanceService.getDistricts(true),
        this.maintenanceService.getUnitOfMeasures(true),
        this.maintenanceService.getQtySizeControls(true)
      ]
    )
      .pipe(map(
        ([priceFileItems]) => {
          return priceFileItems;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addPriceFileItem(dataRecord: any): Observable<PriceFileItem> {
    const url = environment.apiUrl + '/price-file-items';
    return this._http.post<PriceFileItem>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updatePriceFileItem(id: string, itm: any) {
    const url = environment.apiUrl + '/price-file-items/' + id;
    return this._http.patch(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deletePriceFileItem(id: string) {
    const url = environment.apiUrl + '/price-file-items/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  movePriceFileItem(id: number, parentId: number, orderNo: number) {
    let url = environment.apiUrl + '/price-file-items/move/' + id;
    if (parent) {
      url += '?priceFileItemParentId=' + parentId + '&orderNumber=' + orderNo;
    } else {
      url += '?&orderNumber=' + orderNo;
    }

    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  getPriceFileVendors(): Observable<PriceFileVendor[]> {
    const url = environment.apiUrl + '/price-file-vendors';
    return this._http.get<PriceFileVendor[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getPriceFileItemVendorRates(useCache: boolean = false): Observable<PriceFileItemVendorRate[]> {
    if (useCache && this.priceFileItemVendorRates && this.priceFileItemVendorRates.length
      && this.cachCompanyPriceFileItemVendorRates === this.globalService.getCurrentCompanyId()) {
      return of(this.priceFileItemVendorRates);
    } else {
      const url = environment.apiUrl + '/price-file-item-vendor-rates';
      return this._http.get<PriceFileItemVendorRate[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.priceFileItemVendorRates = res; this.cachCompanyPriceFileItemVendorRates = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addPriceFileItemVendorRate(dataRecord: any): Observable<PriceFileItemVendorRate[]> {
    const url = environment.apiUrl + '/price-file-item-vendor-rates';
    return this._http.post<PriceFileItemVendorRate[]>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updatePriceFileItemVendorRate(dataRecord: any): Observable<PriceFileItemVendorRate[]> {
    const url = environment.apiUrl + '/price-file-item-vendor-rates';
    return this._http.patch<PriceFileItemVendorRate[]>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  // addPriceFileVendor(dataRecord: any): Observable<PriceFileVendor> {
  //   const url = environment.apiUrl + '/price-file-vendors';
  //   return this._http.post<PriceFileVendor>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  // }

  // updatePriceFileVendor(id: string, itm: any) {
  //   const url = environment.apiUrl + '/price-file-vendors/' + id;
  //   return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  // }

  deletePriceFileVendor(id: string) {
    const url = environment.apiUrl + '/price-file-vendors/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getPriceFileRates(): Observable<PriceFileRate[]> {
    const url = environment.apiUrl + '/price-file-rates';
    return this._http.get<PriceFileRate[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // addPriceFileRate(dataRecord: any): Observable<PriceFileRate> {
  //   const url = environment.apiUrl + '/price-file-rates';
  //   return this._http.post<PriceFileRate>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  // }

  // updatePriceFileRate(id: string, itm: any) {
  //   const url = environment.apiUrl + '/price-file-rates/' + id;
  //   return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  // }

  deletePriceFileRate(id: number) {
    const url = environment.apiUrl + '/price-file-rates/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  calcEstExtraForDearest(districtId: number, effectiveDateString: string, selectedIds: any) {
    let url = environment.apiUrl + '/price-file-item-vendor-rates/set-est-extra-to-dearest'
      + '?districtId=' + districtId
      + '&effectiveDateString=' + effectiveDateString;

    return this._http.patch(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions());
  }

  setPreferredVendor(districtId: number, vendorId: number, selectedIds: any) {
    let url = environment.apiUrl + '/price-file-item-vendor-rates/set-preferred'
      + '?districtId=' + districtId
      + '&vendorId=' + vendorId;

    return this._http.patch(url, JSON.stringify(selectedIds), this.httpService.getHttpOptions());
  }

  postUploadtemplate(xlFile, deleteExisting: boolean, loadTypeId: number, districtId: number, vendorId: number, priceLoadDate: string,
    updateDescription: boolean, updateUnits: boolean) {
    const options = this.httpService.getHttpFileOptions();
    let url = environment.apiUrl + '/price-file-item-vendor-rates/excel?loadTypeId='
      + loadTypeId + '&districtId=' + districtId;

    if (vendorId) {
      url += '&vendorId=' + vendorId;

      if (updateDescription) {
        url += '&updateDescription=' + updateDescription;
      }
      if (updateUnits) {
        url += '&updateUnits=' + updateUnits;
      }
    }
    if (priceLoadDate) {
      url += '&priceDate=' + priceLoadDate;
    }
    return this._http.post(url, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getPriceFileExcelDump(activeItemsOnly: boolean, currentPricesOnly: boolean, preferredVendorsOnly: boolean): Observable<PDFReports> {
    const url = environment.apiUrl + '/price-file-item-vendor-rates/export?activeItemsOnly=' + activeItemsOnly
      + '&currentPricesOnly=' + currentPricesOnly
      + '&preferredVendorsOnly=' + preferredVendorsOnly;
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getSampleExcelUpload(loadTypeId: number): Observable<PDFReports> {
    const url = environment.apiUrl + '/price-file-item-vendor-rates/load-sample?loadTypeId=' + loadTypeId;
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getDistrictPreferredRate(district: District, priceFileItemId: number, currentCostingDateString: string): number {
    const currentCostingDate = currentCostingDateString.slice(0, 10);

    // check for bad data to skip
    const itemCheck = this.priceFileItemVendorRates.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district.id
      && i.preferredVendorId === i.priceFileVendorId
      && i.isActive
      && !i.effectiveDate);

    if (itemCheck) {
      console.log(itemCheck);
    }

    const itemRec = this.priceFileItemVendorRates.find(i => i.priceFileItemId === priceFileItemId
      && i.districtId === district.id
      && i.preferredVendorId === i.priceFileVendorId
      && i.isActive
      && i.effectiveDate
      && i.effectiveDate.toString().slice(0, 10) <= currentCostingDate);

    if (!itemRec && district.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.maintenanceService.districts.find(i => i.id === district.districtParentId);
      return (this.getDistrictPreferredRate(parentDistrict, priceFileItemId, currentCostingDate));
    } else if (!itemRec || (itemRec.expiryDate && itemRec.expiryDate.toString().slice(0, 10) < currentCostingDate)) {
      return null;
    } else {
      if (this.costsIncEstimatingExtra) {
        return itemRec.rate ? itemRec.rate + (itemRec.estimatingExtra ? itemRec.estimatingExtra : 0)
          : itemRec.estimatingExtra ? itemRec.estimatingExtra : 0;
      } else {
        return itemRec.rate ? itemRec.rate : 0;
      }
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
