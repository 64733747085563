import { formatDate } from 'devextreme/localization';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  clearCaches = new EventEmitter<null>();

  constructor(
    private globalService: GlobalService,
    private auth: AuthService
  ) {
    this.globalService.companySelected.subscribe(() => {
      this.clearCaches.emit();
    });
  }

  getHttpOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getUserId() ?? '0',
      })
    };
    return httpOptions;
  }

  getHttpFileOptions(): { headers: HttpHeaders; } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': require('uuid').v4(),
        'client-version': environment.fullVersion,
        'company-id': this.globalService.getCurrentCompanyId(),
        'user-id': this.auth.getUserId() ?? '0'
      })
    };
    return httpOptions;
  }

  handleHTTPError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

  jsonReplacer(key, value) {
    if (this[key] instanceof Date) {
      return formatDate(this[key], 'yyyy-MM-dd');
    }
    return value;
  }
}
