import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceHelperService {

  sections = ['Price-Books', 'Mark-Ups', 'Vendors', 'Units', 'Phases', 'Rounding'];
  selectedSection = 'Price-Books';

  constructor() { }

}
