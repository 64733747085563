import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { CompanyMarginsComponent } from './company-margins/company-margins.component';
import { CompanyMarginService } from '../services/felixApi/company-margin.service';
import { VendorsComponent } from './vendors/vendors.component';
import { PhasesComponent } from './phases/phases.component';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { UnitOfMeasureComponent } from './unit-of-measure/unit-of-measure.component';
import { QtySizeControlComponent } from './qty-size-control/qty-size-control.component';
import { DistrictsComponent } from './districts/districts.component';
import { MaintenanceComponent } from './maintenance.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        MaintenanceComponent,
        CompanyMarginsComponent,
        VendorsComponent,
        PhasesComponent,
        UnitOfMeasureComponent,
        QtySizeControlComponent,
        DistrictsComponent
    ],
    providers: [
        CompanyMarginService,
        MaintenanceService
    ]
})
export class MaintenanceModule { }
