<dx-popup class="popup" [width]="popupWidth" [height]="popupHeight" [showTitle]="true" [title]="titleText"
  [dragEnabled]="false" [hideOnOutsideClick]="true" [visible]="popupVisible" [shading]="false"
  (onHiding)="closeToolbox($event)">
  <div *dxTemplate="let data of 'content'">

    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <div *ngIf="!loading">
      <!-- if !recipeParentId we are doing the top toolbox -->
      <js-select-recipes *ngIf="!recipeParentId" [selectMultiple]="true" [onlyClasses]="true"
        (emitSelection)="setSelectedRecipes($event)"></js-select-recipes>

      <br *ngIf="!recipeParentId">

      <div class="dx-field">
        As at
        <dx-date-box [(value)]="reCostingDate" type="date" [calendarOptions]="{ showTodayButton: true }" [width]="180"
          class="inlineClass">
        </dx-date-box>
      </div>

      <div class="dx-field">
        <dx-check-box [(value)]="updateOptionLists" text="Update any associated Option Lists?">
        </dx-check-box>
      </div>

      <div>Note: Only recipes with a margin specified will be priced</div>
      <div>Note: Locked recipes will still be updated</div>
      <div *ngIf="updateOptionLists">Note: Option list records with Price Type "Note" will <b>not</b> be updated</div>

      <dx-button id="go-button" text="Go" type="success" (onClick)="recostRecipesGo()">
      </dx-button>
    </div>

    <div *ngIf="loading && recordCount && !recipeParentId">Class {{recordCount}} of {{selectedRecipeIds.length}}</div>
  </div>
</dx-popup>
