<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Set Active</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!loading" class="modal-body">
  <dx-select-box [items]="selectedOptions" [(value)]='selectedOption'>
  </dx-select-box>

  <br>
  <js-select-recipes [selectMultiple]="true" (emitSelection)="setSelectedRecipes($event)"></js-select-recipes>
  <br>

  <dx-button [disabled]="!selectedRecipeIds || !selectedRecipeIds.length" text="Update" type="default"
    (onClick)="runUpdate()">
  </dx-button>
</div>

<js-loading-spinner *ngIf="loading"></js-loading-spinner>
