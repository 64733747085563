import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { AuthService } from './../../services/auth.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Recipe } from '../../dtos/recipe';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';

@Component({
  selector: 'js-recipe-search',
  templateUrl: './recipe-search.component.html',
  styleUrls: ['./recipe-search.component.scss']
})
export class RecipeSearchComponent implements OnInit {
  @Input() selectMultiple: boolean;

  allRecipes: Recipe[] = [];
  selectedRowKeys: any[] = [];
  gridMaxHeight: number;
  expandedRowKeys: Number[] = [];
  gridString: any;
  selectionMode: string;

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService) {
    this.download = this.download.bind(this);
  }

  ngOnInit() {
    this.selectionMode = this.selectMultiple ? 'multiple' : 'single';

    this.setWidths();

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      // note all recipeClasses can be seen
      this.allRecipes = this.allRecipes.concat(this.estimatingService.recipeGroups);
    }
    else {
      this.allRecipes = this.allRecipes.concat(this.estimatingService.recipeGroups.filter(i => !i.isAdminOnly));
    }

    this.allRecipes = this.allRecipes.concat(this.estimatingService.recipes);

    this.getRecipeRates();
  }

  setWidths() {
    this.gridMaxHeight = window.innerHeight - 250;
  }

  close() {
    this.activeModal.dismiss();
  }

  select() {
    if (this.selectMultiple) {
      this.activeModal.close(this.selectedRowKeys);
    } else {
      const selectedRecipe = this.allRecipes.find(i => i.id === this.selectedRowKeys[0]);
      this.activeModal.close(selectedRecipe);
    }
  }

  getRecipeRates() {
    this.allRecipes.forEach(recipe => {
      let district = this.maintenanceService.districts.find(i => i.districtParentId === null); // initialise

      // we deep calc the rate for this recipe
      if (district.id !== recipe.districtId) {
        district = this.maintenanceService.districts.find(i => i.id === recipe.districtId);
      }
      recipe.rate = this.estimatingService
        .calcRecipeRate(recipe.id, district, false);

      const recipeSelling = this.estimatingService.recipeSellingRates
        .find(i => i.recipeId === recipe.id
          && i.districtId === recipe.districtId);

      if (recipeSelling) {
        recipe.sellingPrice = recipeSelling.rate;
        recipe.sellingPriceLastUpdated = recipeSelling.effectiveDate;
      } else {
        recipe.sellingPrice = null;
        recipe.sellingPriceLastUpdated = null;
      }
    });
  }

  download() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Recipes', { pageSetup: { paperSize: 9, orientation: 'landscape' } });

    worksheet.columns = [
      { header: 'Code', key: 'code', width: 15 },
      { header: 'Description', key: 'description', width: 70 },
      { header: 'Cost', key: 'cost', width: 15 },
      { header: 'Selling', key: 'cost', width: 15 },
      { header: 'Units', key: 'cost', width: 10 }
    ];

    this.allRecipes.filter(i => i.recipeCode && i.recipeCode !== '').forEach(recipe => {
      worksheet.addRow([
        recipe.recipeCode,
        recipe.description,
        recipe.rate ? Math.round(recipe.rate * 100) / 100 : 0,
        recipe.sellingPrice ? Math.round(recipe.sellingPrice * 100) / 100 : 0,
        recipe.unitOfMeasure
      ]);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Recipes.xlsx');
    });
  }
}
