<div class="modal-header">
  <h4 class="modal-title" placement="bottom">Copy {{recipe.recipeCode}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-text-box [showClearButton]="true" placeholder="Enter new code..." [(value)]="newCode">
  </dx-text-box>

  <dx-button class="mt-2" text="Go" type="success" (onClick)="copyRecipe()" [disabled]="!newCode || newCode === ''">
  </dx-button>
</div>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>
