import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  public classesLoadingSpinnerVisible = false;

  constructor() { }

}
