import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../services/felixApi/company.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-update-config',
  templateUrl: './update-config.component.html',
  styleUrls: ['./update-config.component.scss']
})
export class UpdateConfigComponent implements OnInit, OnDestroy {
  @Input() configId: number;
  @Input() configName: string;

  loading = false;
  formData: CompanyConfiguration;
  companyConfiguration: CompanyConfiguration;
  updatedData: any;
  subscriptions: Subscription[] = [];

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private companyService: CompanyService,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.companyConfiguration = this.globalService.companyConfigurations.find(i => i.configurationId === this.configId);

    if (this.companyConfiguration) {
      this.companyConfiguration.configBoolean = this.companyConfiguration.configurationValue ? true : false;
      this.formData = this.companyConfiguration;
    } else {
      this.formData = new CompanyConfiguration;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this._activeModal.close();
  }

  save() {
    if (this.companyConfiguration) {
      // edit
      this.updatedData = {};
      this.updatedData.configurationValue = this.formData.configBoolean ? 1 : 0;
      this.loading = true;

      this.subscriptions.push(
        this.companyService.updateCompanyConfigs(this.configId, this.updatedData)
          .subscribe({
            next: () => {
              this.companyConfiguration.configurationValue = this.updatedData.configurationValue;
              this.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      // add
      this.loading = true;

      this.updatedData = {};
      this.updatedData.configurationId = this.configId;
      this.updatedData.configurationValue = this.formData.configBoolean ? 1 : 0;
      this.updatedData.configurationString = '';

      this.subscriptions.push(
        this.companyService.addCompanyConfigs(this.updatedData)
          .subscribe({
            next: (config) => {
              this.globalService.companyConfigurations.push(config);
              this.close();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }
  }
}
