import { GridService } from './../../services/grid.service';
import { PriceFileService } from './../../services/felixApi/price-file.service';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { GlobalService } from './../../services/global.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Recipe } from '../../dtos/recipe';
import { Phase } from '../../dtos/phase';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { RecipeLine } from '../../dtos/recipe-line';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { District } from '../../dtos/district';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-explode-in-memory',
  templateUrl: './explode-in-memory.component.html',
  styleUrls: ['./explode-in-memory.component.scss']
})
export class ExplodeInMemoryComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  @ViewChild('inMemoryGrid') dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  descriptionWidth: number;
  gridMaxHeight: number;
  dataSource: CustomStore;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default', isShowOnPO: false }];
  unitOfMeasures: UnitOfMeasure[];
  currentDistrict: District;
  recipLines: RecipeLine[];

  constructor(
    private estimatingService: EstimatingService,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private maintenanceService: MaintenanceService,
    private priceFileService: PriceFileService,
    public gridService: GridService
  ) {
    this.calculateLineTotal = this.calculateLineTotal.bind(this);
    this.getGroupTitle = this.getGroupTitle.bind(this);
    this.getRate = this.getRate.bind(this);
  }

  ngOnInit() {
    this.setWidths();
    this.currentDistrict = this.maintenanceService.districts.find(i => i.id === this.recipe.districtId);
    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.unitOfMeasures = this.maintenanceService.unitOfMeasures;

    this.setUpDataSet();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.descriptionWidth = window.innerWidth < 1900 ? 500 : window.innerWidth - 1400;
    this.gridMaxHeight = window.innerHeight - 190;
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.explodeRecipeInMemory(this.recipe.id).subscribe({
              next: (res) => {
                res.forEach(recipeLine => {
                  if (recipeLine.recipeItemId) {
                    const priceFileItem = this.priceFileService.recipeAndItems.find(i => i.recipeId === recipeLine.recipeItemId);
                    recipeLine.masterGroupCostCentre = priceFileItem?.masterGroupCostCentre;
                    recipeLine.subGroupItemDesc = priceFileItem?.subGroupItemDesc;
                    recipeLine.rate = priceFileItem?.rate;
                  } else if (recipeLine.priceFileItemId) {
                    const priceFileItem = this.priceFileService.recipeAndItems.find(i => i.priceFileItemId === recipeLine.priceFileItemId);
                    recipeLine.masterGroupCostCentre = priceFileItem?.masterGroupCostCentre;
                    recipeLine.subGroupItemDesc = priceFileItem?.subGroupItemDesc;
                    recipeLine.rate = priceFileItem?.rate;
                  }
                });
                this.recipLines = res;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'isToBeChecked' && e.data.isToBeChecked) {
      e.cellElement.classList.add('redWhite');
    }
  }

  getSubGroupTitle(cellInfo) {
    return cellInfo.data.key ? cellInfo.data.key?.split(';')[1] : 'Items';
  }

  getGroupTitle(cellInfo) {
    const recipeLinesForGroup = this.recipLines.filter(i => i.masterGroupCostCentre === cellInfo.data.key);
    let groupTotal = 0;
    recipeLinesForGroup.forEach(recipeLine => {
      groupTotal += this.calculateLineTotal(recipeLine);
    });
    return cellInfo.data.key ? cellInfo.data.key?.split(';')[1] + '   $' + groupTotal.toFixed(2) : 'Ad-Hoc';
  }

  calcGroupSortValue(data) {
    return data.masterGroupCostCentre?.split(';')[0];
  }

  calculateSubGroupSortValue(data) {
    return data.subGroupItemDesc?.split(';')[0];
  }

  getRate(recipeLine: RecipeLine) {
    if (recipeLine.recipeCode && recipeLine.recipeCode !== '' && recipeLine.rate === null) {
      return 'Invalid';
    }
    return recipeLine.rate;
  }

  calculateLineTotal = (rowData) => {
    if (rowData) {
      let qty = !rowData.quantity || isNaN(rowData.quantity) ? 0 : rowData.quantity;
      const rate = !rowData.rate || isNaN(rowData.rate) ? 0 : rowData.rate;

      const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === rowData.unitOfMeasureId);
      if (unitOfMeasure && unitOfMeasure.costIsPer) {
        qty /= unitOfMeasure.costIsPer;
      }
      return qty * rate;
    } else {
      return 0;
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Expand All Rows'
        }
      });
  }

  collapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  expandAll() {
    this.dataGrid.instance.expandAll();
  }

  close() {
    this.activeModal.close();
  }
}
