<dx-tree-list #searchtreelist id="searchtreelist" [dataSource]="allRecipes" keyExpr="id" parentIdExpr="recipeParentId"
  remoteOperations="false" [showRowLines]="false" [showColumnLines]="false" [showBorders]="true"
  [columnAutoWidth]="true" [rootValue]=null [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedRowKeys"
  [height]="gridHeight" [(expandedRowKeys)]="expandedRowKeys" (onSelectionChanged)="onSelectionChanged($event)">

  <dxo-search-panel [visible]="true" [width]="300"></dxo-search-panel>
  <dxo-selection [mode]="selectionMode" [recursive]="true"></dxo-selection>
  <dxo-scrolling mode="standard"></dxo-scrolling>
  <dxo-paging [enabled]="false"> </dxo-paging>

  <dxo-toolbar>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>

  <dxi-column dataField="description" [caption]="classDescription"> </dxi-column>
  <dxi-column *ngIf="!onlyClasses" dataField="recipeCode" caption="Code" [width]="120"> </dxi-column>
  <dxi-column *ngIf="!onlyClasses" dataField="rate" caption="Cost" dataType="number" [width]="110" format="#,##0.00">
  </dxi-column>
  <dxi-column *ngIf="!onlyClasses" dataField="sellingPrice" dataType="number" [width]="110" format="#,##0.00">
  </dxi-column>
  <dxi-column *ngIf="!onlyClasses" dataField="unitOfMeasure" caption="Units" [width]="120"> </dxi-column>
</dx-tree-list>
