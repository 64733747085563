import { EventEmitter, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { GlobalService } from './global.service';
import { AreaPermission } from '../dtos/areaPermission';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { User } from '../dtos/user';
import { SessionName } from '../dtos/session';

@Injectable()
export class AuthService {

  private userEmail: string;
  private currentUser: User;

  public signedIn = false;
  isLoggedInChanged = new EventEmitter<boolean>();

  areaPermissions: AreaPermission[] = [];
  subscriptionSuccess: any;
  subscriptionFail: any;

  versionIncorrect = false; // do we have the right version - checked by the refresh guard
  currentVersion: string;
  correctVersion: string;

  constructor(
    private globalService: GlobalService,
    private msalService: MsalService
  ) { }

  signIn() {
    this.signedIn = true;
    this.isLoggedInChanged.emit(this.signedIn);
  }

  signOut() {
    this.signedIn = false;
    this.userEmail = null;
    this.msalService.logout();
    sessionStorage.removeItem(SessionName);
    this.isLoggedInChanged.emit(this.signedIn);
  }

  setUserEmail(email: string) {
    this.userEmail = email;
  }

  getUserEmail(): string {
    return this.userEmail;
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  setCurrentUser(inUser: User) {
    this.currentUser = inUser;
  }

  getCurrentUserId(): number {
    return this.currentUser?.id;
  }

  getUserId(): string {
    if (this.currentUser !== undefined && this.currentUser !== null) {
      return this.currentUser.id.toString();
    }
    return '';
  }

  getMsalUserEmail() {
    /* assuming 1 active account at a time */
    const accs = this.msalService.instance.getAllAccounts();
    /* assuming one email per account */
    return accs.length > 0 ? accs[accs.length - 1].idTokenClaims['emails'][0] : null;
  }

  getSelectionsPermissions(permissionArea: string): string {
    let permission = 'none';
    this.areaPermissions.forEach(retPermission => {
      if (retPermission.applicationArea === permissionArea) {
        if (retPermission.permissionType) {
          permission = retPermission.permissionType;
        }
      }
    });
    return permission;
  }

  isSuperUser() {
    return this.getCurrentUser()?.isSuperUser;
  }

  isAdminOrSuper() {
    return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Admin || this.getCurrentUser()?.isSuperUser;
  }

  isClient() {
    return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Client;
  }

  isAssociate() {
    return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Associate;
  }
}
