import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Vendor } from '../../dtos/vendor';
import { UtilsService } from '../utils.service';
import { VendorPayable } from '../../dtos/vendor-payable';
import { HttpService } from '../http.service';
import { environment } from '../../../environments/environment';
import { VendorGroup } from '../../dtos/vendor-group';
import { Invoice } from '../../dtos/invoice';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  vendors: Vendor[];
  cachCompany: string;
  vendorPayables: VendorPayable[];
  vendorPayablesCompany: string;
  vendorGroups: VendorGroup[];
  vendorGroupsCompany: string;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private utilsService: UtilsService) { }

  getVendors(useCache: boolean = true, activeOnly: boolean): Observable<Vendor[]> {
    if (useCache && this.vendors && this.vendors.length && this.cachCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendors);
    } else {
      return this._http.get<Vendor[]>(environment.apiUrl +
        '/vendors?activeOnly=' + activeOnly, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendors = res; this.cachCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorPayables(useCache: boolean = true): Observable<VendorPayable[]> {
    if (useCache && this.vendorPayables && this.vendorPayables.length
      && this.vendorPayablesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorPayables);
    } else {
      return this._http.get<VendorPayable[]>(environment.apiUrl +
        '/vendor-payables', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorPayables = res; this.vendorPayablesCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorGroups(useCache: boolean): Observable<VendorGroup[]> {
    if (useCache && this.vendorGroups && this.vendorGroups.length
      && this.vendorGroupsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.vendorGroups);
    } else {
      return this._http.get<VendorGroup[]>(environment.apiUrl +
        '/vendor-groups', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.vendorGroups = res; this.vendorGroupsCompany = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getVendorsWithPayables(useCache: boolean = true, activeOnly: boolean): Observable<Vendor[]> {
    return forkJoin(
      [this.getVendors(useCache, activeOnly),
      this.getVendorPayables(useCache),
      this.getVendorGroups(useCache)]
    )
      .pipe(map(
        ([vendors, vendorPayables]) => {
          vendors.forEach(vendor => {
            vendor.vendorPayableIds = [];
            vendorPayables.filter(i => i.orderVendorId === vendor.id).forEach(vendorPayable => {
              vendor.vendorPayableIds.push(vendorPayable.payVendorId);
            });
          });
          return vendors;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addVendor(dataRecord: any): Observable<Vendor> {
    const url = environment.apiUrl + '/vendors';
    return this._http.post<Vendor>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.vendors.push(res);
        })
      );
  }

  updateVendor(id: string, itm: any): Observable<Vendor> {
    const url = environment.apiUrl + '/vendors/' + id;
    return this._http.patch<Vendor>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.editCache(res, this.vendors);
        })
      );
  }

  deleteVendor(id: string) {
    const url = environment.apiUrl + '/vendors/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.deleteFromCache(id, this.vendors);
        })
      );
  }

  postUploadtemplate(xlFile) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(environment.apiUrl
      + '/vendors-load/excel/', xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }


  // get invoices to see when a vendor is last used
  getInvoices(): Observable<Invoice[]> {
    const url = environment.apiUrl + '/invoices?sortByLatestDate=true';

    return this._http.get<Invoice[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
