<dx-tab-panel #panel
  height="calc(100vh - 49px)"
  [dataSource]="helper.sections"
  [selectedIndex]="2"
  [loop]="false"
  [animationEnabled]="true"
  [swipeEnabled]="false"
  (onTitleClick)="tabSelected($event)">

  <div *dxTemplate="let title of 'title'">
    <span [ngClass]="{'header': title == helper.selectedSection}">{{title}}</span>
  </div>

  <div *dxTemplate="let section of 'item'">
    <ng-container [ngSwitch]="section">
      <div style="height: calc(100vh - 100px);">
        <js-districts *ngIf="section === 'Price-Books'"></js-districts>
        <js-company-margins *ngIf="section === 'Mark-Ups'"></js-company-margins>
        <js-vendors *ngIf="section === 'Vendors'"></js-vendors>
        <js-unit-of-measure *ngIf="section === 'Units'"></js-unit-of-measure>
        <js-phases *ngIf="section === 'Phases'"></js-phases>
        <js-qty-size-control *ngIf="section === 'Rounding'"></js-qty-size-control>
      </div>
    </ng-container>
  </div>
</dx-tab-panel>
