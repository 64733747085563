<div class="modal-header">
  <h3 class="modal-title">Update Config Entry</h3>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <dx-form id="form" *ngIf="!loading" [(formData)]="formData" [readOnly]="false" [showColonAfterLabel]="true"
    validationGroup="formData" labelLocation="left" [minColWidth]="500" [colCount]="1" [width]="800">

    <dxi-item dataField="configBoolean" editorType="dxCheckBox">
      <dxo-label location="left" alignment="right" [text]="configName">
      </dxo-label>
    </dxi-item>
  </dx-form>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
</div>

<div class="modal-footer">
  <button *ngIf="!loading" class="btn btn-sm btn-primary" (click)="save()">Save</button>
</div>
