import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { BrowserCacheLocation, InteractionType,
  IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalModule, MsalInterceptor,
  MsalInterceptorConfiguration, MsalGuardConfiguration,
  MSAL_INSTANCE, MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalBroadcastService, MsalRedirectComponent, MsalGuard } from '@azure/msal-angular';

import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { RecipesModule } from './recipes/recipes.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { LoginModule } from './login/login.module';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { UserService } from './services/felixApi/user.service';
import { CompanyService } from './services/felixApi/company.service';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { RefreshGuard } from './services/refresh-guard.service';
import { MenuComponent } from './shared/menu/menu.component';
import { PriceFileItemsModule } from './price-file/price-file-items.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariablesService } from './services/global-variables.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error) {
    console.error(message);
    // how to log to a service?
  } else {
    // console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: environment.applicationUrl,
      knownAuthorities: [environment.authoryDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes);
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    RecipesModule,
    PriceFileItemsModule,
    MaintenanceModule,
    SharedModule,
    LoginModule,
    MsalModule,
    NgbModule
  ],
  providers: [
    UserService,
    CompanyService,
    GlobalService,
    AuthService,
    NotificationService,
    GlobalVariablesService,
    RefreshGuard,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
