import { Injectable } from '@angular/core';
import { formatDate } from 'devextreme/localization';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getErrorString(err): string {
      if (err && err.error && err.error.message) {
        return JSON.stringify(err.error.message);
      } else {
        return JSON.stringify(err);
      }
  }

  editCache(object, cache: any[]) {
    const match = cache.filter(t => t.id === object.id)[0];
    if (match) {
        const index = cache.indexOf(match);
        cache[index] = object;
    } else {
        console.log('couldnt edit cache!');
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
        const index = cache.indexOf(match);
        cache.splice(index, 1);
    } else {
        console.log('couldnt delete from cache!');
    }
  }

  roundEven(val: number): number {
    if (Math.abs(val % 1) === 0.5) {
      return (val = Math.round(val), val - (val % 2) * Math.sign(val));
    }
    return Math.round(val);
  }

  convertStringToDate(date: any): Date {
    if (!date) {
      return null;
    }

    if (date instanceof Date) {
      return date;
    } else {
      // + 12 hours to account for UTC time
      const newDate = new Date(+date.substr(0, 4), +date.substr(5, 2) - 1, +date.substr(8, 2), +date.substr(11, 2) + 12, 0, 0, 0);
      return newDate;
    }
  }
}
