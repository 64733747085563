import { GridService } from './../../services/grid.service';
import { CostCentre } from './../../dtos/cost-centre';
import { MaintenanceService } from './../../services/felixApi/maintenance.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { Subscription, of } from 'rxjs';
import { ReportGridItem } from '../../dtos/report-grid-item.type';
import { PriceFileService } from '../../services/felixApi/price-file.service';
import { RecipeSearchComponent } from '../recipe-search/recipe-search.component';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { District } from '../../dtos/district';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { RecipeLine } from '../../dtos/recipe-line';
import { Summary } from "devextreme/ui/data_grid";
import { SummaryTotalItem } from "devextreme/ui/data_grid"
import { JobSearchModalComponent } from '../../shared/job-search-modal/job-search-modal.component';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { Job } from '../../dtos/job';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'js-cost-centre-summary',
  templateUrl: './cost-centre-summary.component.html',
  styleUrls: ['./cost-centre-summary.component.scss']
})
export class CostCentreSummaryComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: DataSource;
  gridColumns: ReportGridItem[] = [];
  reportGridData: any[] = [];
  costCentres: CostCentre[];
  summary: Summary = {};
  summaryTotalItems: SummaryTotalItem[] = [];
  loading: boolean;
  recipeLines: RecipeLine[];
  loadingSpinnerOn: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private jobService: JobService,
    private priceFileService: PriceFileService,
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    public gridService: GridService) {
  }

  ngOnInit() {
    this.setHeight();
    this.setUpRows();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setHeight() {
    this.gridHeight = window.innerHeight - 300;
  }

  setUpRows() {
    this.addToColumns('costCentreOrderNumber', '', 'number', '', null, false);
    this.addToColumns('costCentreCode', 'Code', 'string', '', 80, true);
    this.addToColumns('description', 'Description', 'string', '', null, true);

    this.reportGridData = [];

    this.costCentres = this.priceFileService.priceFileItemGroups.filter(i => !i.priceFileItemParentId);
    this.costCentres.push({ id: null, priceFileCode: 'Ad-Hoc', description: 'Ad-Hoc', orderNumber: 99999 })

    this.costCentres.forEach(item => {
      const reportGridRow = {};
      reportGridRow['costCentreOrderNumber'] = item.orderNumber;
      reportGridRow['costCentreCode'] = item.priceFileCode;
      reportGridRow['description'] = item.description;
      this.reportGridData.push(reportGridRow);
    });

    this.summaryTotalItems = [];
    this.summary.totalItems = this.summaryTotalItems;
    this.summary.skipEmptyValues = true;
    this.summaryTotalItems.push({ column: 'costCentreCode', showInColumn: 'costCentreCode', summaryType: 'count', displayFormat: '{0} Rows', valueFormat: '#,##0', skipEmptyValues: true });

    this.setUpDataSet();
  }

  addToColumns(dataField: string, caption: string, dataType: string, format: string, width: number, visible: boolean) {
    const col = {
      dataField: dataField,
      caption: caption,
      dataType: dataType,
      format: format,
      visible: visible,
      width: width
    };

    this.gridColumns.push(col);
  }

  setUpDataSet() {
    this.loading = true;
    this.loadingSpinnerOn = false;
    setTimeout(() => {
      this.loading = false;
      this.dataSource = new DataSource({
        key: 'costCentreCode',
        load: () => this.reportGridData
      });
    }, 400);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add Recipe',
          onClick: this.addRecipe.bind(this)
        }
      },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add Job',
          onClick: this.addJob.bind(this)
        }
      });
  }

  close() {
    this._activeModal.close(null);
  }

  addRecipe() {
    const modalRef = this.modalService.open(RecipeSearchComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(selectedRecipe => {
      if (selectedRecipe.recipeTypeId !== RecipeTypeEnum.Group) {
        // add recipe column
        this.addToColumns('recipe' + selectedRecipe.id, selectedRecipe.description, 'number', '#,###.00', 120, true);
        this.summaryTotalItems.push({ column: 'recipe' + selectedRecipe.id, showInColumn: 'recipe' + selectedRecipe.id, summaryType: 'sum', displayFormat: '{0}', valueFormat: '#,###.00', skipEmptyValues: true });

        // the cost needs the district
        const currentDistrict = this.maintenanceService.districts.find(i => i.id === selectedRecipe.districtId);

        this.explodeRecipe(selectedRecipe.id, currentDistrict);
      }
    }, () => {
    });
  }

  explodeRecipe(recipeId: number, currentDistrict: District) {
    this.recipeLines = [];
    this.loadingSpinnerOn = true;

    this.subscriptions.push(
      this.estimatingService.explodeRecipeInMemory(recipeId).subscribe({
        next: (res) => {
          this.recipeLines = res;

          this.recipeLines.forEach(recipeLine => {
            if (recipeLine.priceFileItemId) {
              let priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === recipeLine.priceFileItemId);
              let priceFileSubGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileItem.priceFileItemParentId);
              let costCentre = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileSubGroup.priceFileItemParentId);
              recipeLine.costCentreId = costCentre.id;
            } else {
              recipeLine.costCentreId = null;
            }
          });

          this.costCentres.forEach(costCentre => {
            let costCentreTotal = 0;
            this.recipeLines.filter(i => i.costCentreId === costCentre.id).forEach(recipeLine => {
              let lineRate = recipeLine.rate;
              if (recipeLine.priceFileItemId) {
                lineRate = this.priceFileService
                  .getDistrictPreferredRate(currentDistrict, recipeLine.priceFileItemId, this.estimatingService.currentCostingDateString);
              }
              costCentreTotal += this.calculateLineTotal(recipeLine, isNaN(lineRate) ? 0 : lineRate);
            });

            if (costCentreTotal) {
              const reportGridRowToUpdate = this.reportGridData.find(i => i['costCentreCode'] === costCentre.priceFileCode);
              reportGridRowToUpdate['recipe' + recipeId] = costCentreTotal;
            }
          });

          this.setUpDataSet();
        }, error: (err) => {
          this.loadingSpinnerOn = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  calculateLineTotal(recipeLine: RecipeLine, lineRate: number) {
    let qty = !recipeLine.quantity || isNaN(recipeLine.quantity) ? 0 : recipeLine.quantity;
    const rate = !lineRate || isNaN(lineRate) ? 0 : lineRate;

    const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
    if (unitOfMeasure && unitOfMeasure.costIsPer) {
      qty /= unitOfMeasure.costIsPer;
    }
    return qty * rate;
  }

  addJob() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((selectedJob: Job) => {
      // add recipe column
      this.addToColumns('job' + selectedJob.id, 'Job ' + selectedJob.jobNumber, 'number', '#,###.00', 120, true);
      this.summaryTotalItems.push({ column: 'job' + selectedJob.id, showInColumn: 'job' + selectedJob.id, summaryType: 'sum', displayFormat: '{0}', valueFormat: '#,###.00', skipEmptyValues: true });

      // read the order lines and summarise
      this.getOrderLines(selectedJob.id);
    }, () => {
    });
  }

  getOrderLines(jobId: number) {
    this.loadingSpinnerOn = true;
    this.subscriptions.push(
      this.jobService.getOrderLines(jobId).subscribe({
        next: (res) => {
          this.costCentres.forEach(costCentre => {
            let costCentreTotal = 0;
            res.filter(i => i.costCentreId === costCentre.id && !i.isDeleted)
              .forEach(orderLine => {
                costCentreTotal += orderLine.lineTotal ?? 0;
              });

            if (costCentreTotal) {
              const reportGridRowToUpdate = this.reportGridData.find(i => i['costCentreCode'] === costCentre.priceFileCode);
              reportGridRowToUpdate['job' + jobId] = costCentreTotal;
            }
          });

          this.setUpDataSet();
        }, error: (err) => {
          this.loadingSpinnerOn = false;
          this.notiService.notify(err);
        }
      })
    );
  }
}
