<div class="page">
  <i (click)="toggleHelp()" class="material-icons md-18 help-icon">help</i>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-tree-list #treeList id="tree-list" [dataSource]="treeListDataSource" keyExpr="id" [autoExpandAll]="true"
    class="max-w-800 m-auto tabbed-grid-help-icon" parentIdExpr="districtParentId" remoteOperations="false"
    [showRowLines]="false" [showColumnLines]="false" [showBorders]="true" [columnAutoWidth]="true" [rootValue]=null
    [expandedRowKeys]="[0]" [wordWrapEnabled]="true" [(selectedRowKeys)]="selectedRowKeys"
    (onEditorPreparing)="editorPreparing($event)" (onInitNewRow)="initNewRow($event)"
    (onCellPrepared)="cellPrepared($event)">

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>

    <dxo-row-dragging [onReorder]="onReorder" [allowDropInsideItem]="true" [allowReordering]="true"
      [showDragIcons]="false"></dxo-row-dragging>

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="full">
    </dxo-editing>

    <dxo-selection mode="single"></dxo-selection>

    <dxo-scrolling mode="standard"></dxo-scrolling>

    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="description" caption="Price Book">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="orderNumber" [visible]="false" sortOrder="asc"></dxi-column>

    <dxi-column type="buttons">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button hint="Add Child" icon="add" [onClick]="addGroup"></dxi-button>
    </dxi-column>

  </dx-tree-list>


  <dx-popup class="popup" [width]="370" [height]="220" [showTitle]="true" title="Tips" [dragEnabled]="false"
    [hideOnOutsideClick]="true" [(visible)]="helpPopupVisible">
    <div *dxTemplate="let data of 'content'">
      You can click and drag groups to reorder.
    </div>
  </dx-popup>

</div>
