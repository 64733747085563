import { Injectable, EventEmitter } from '@angular/core';

import { Company } from '../dtos/company';
import { CompanyConfiguration } from '../dtos/company-configuration';
import { TruthEngineSession, SessionName } from '../dtos/session';
import { Job } from '../dtos/job';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  private currentCompany: Company;
  companySelected = new EventEmitter<Company>();
  companyConfigurations: CompanyConfiguration[] = [];

  private areaSelected = '';
  areaChanged = new EventEmitter<string>();

  menuDisable = new EventEmitter<boolean>();

  innerWidth: number;
  innerWidthChanged = new EventEmitter<number>();
  innerHeight: number;
  innerHeightChanged = new EventEmitter<number>();
  selectonsInnerWidth: number;
  selectonsInnerWidthChanged = new EventEmitter<number>();
  recipeLinesUpdated = new EventEmitter<number>();

  constructor() { }

  isHttps() {
    return window.location.protocol.startsWith('https') || window.location.hostname === 'localhost';
  }

  recipeLinesUpdatedEmit(recipeId: number) {
    this.recipeLinesUpdated.emit(recipeId);
  }

  setInnerWidth(width: number) {
    this.innerWidth = width;
    this.innerWidthChanged.emit(this.innerWidth);
  }

  setSelectionsInnerWidth(width: number) {
    this.selectonsInnerWidth = width;
    this.selectonsInnerWidthChanged.emit(this.selectonsInnerWidth);
  }

  setInnerHeight(height: number) {
    this.innerHeight = height;
    this.innerHeightChanged.emit(this.innerHeight);
  }

  setAreaSelected(area) {
    this.areaSelected = area;
    this.setSessionAtt('areaSelected', area);
    this.areaChanged.emit(this.areaSelected);
  }

  setCurrentCompany(company: Company) {
    this.currentCompany = company;
    this.setSessionAtt('currentCompanyId', company.id);
    this.companySelected.emit(this.currentCompany);
  }

  getJobString(job: Job): string {
    let jobstring = '';

    if (job && job.jobAddress) {
      if (job.jobAddress.lotNumber && job.jobAddress.lotNumber.trim() !== '') {
        jobstring = jobstring.concat('Lot ' + job.jobAddress.lotNumber.trim());
      }
      if (job.jobAddress.streetNumber && job.jobAddress.streetNumber.trim() !== '') {
        jobstring = jobstring.concat(' #' + job.jobAddress.streetNumber.trim());
      }
      if (job.jobAddress.streetName1 && job.jobAddress.streetName1.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.streetName1.trim());
      }
      if (job.jobAddress.streetName2 && job.jobAddress.streetName2.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.streetName2.trim());
      }
      if (job.jobAddress.suburbTown && job.jobAddress.suburbTown.trim() !== '') {
        jobstring = jobstring.concat(' ' + job.jobAddress.suburbTown.trim());
      }
    }
    return jobstring;
  }

  getCurrentCompany(): Company {
    return this.currentCompany;
  }

  clearCurrentCompany() {
    this.currentCompany = null;
  }

  isCompSet() {
    if (this.currentCompany) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentCompanyId(): string {
    if (this.currentCompany !== undefined && this.currentCompany.id !== undefined && this.currentCompany.id !== null) {
      return this.currentCompany.id.toString();
    }
    return '';
  }

  isSalesVariationsActive() {
    return this.currentCompany.salesVariationsActive;
  }

  getSessionObject(): TruthEngineSession {
    if (sessionStorage.getItem(SessionName)) {
      return JSON.parse(sessionStorage.getItem(SessionName));
    } else {
      const session = new TruthEngineSession();
      sessionStorage.setItem(SessionName, JSON.stringify(session));
      return JSON.parse(sessionStorage.getItem(SessionName));
    }
  }

  setSessionAtt(att: string, obj: any) {
    const session = this.getSessionObject();
    session[att] = obj;
    const sessionString = JSON.stringify(session);
    sessionStorage.setItem(SessionName, sessionString);
  }

  getCompanyConfigValue(id: number): number {
    let returnVal = null;
    this.companyConfigurations?.forEach(element => {
      if (element.configurationId === id) {
        returnVal = element.configurationValue;
      }
    });

    return returnVal;
  }

  sortBy(name: string, secondarySort: boolean | ((o, p) => number), isAscending = true): (o, p) => number {
    const reverseMutliplier = isAscending ? 1 : -1;
    return function (o, p) {
      let a, b;
      let result;
      a = o[name];
      b = p[name];
      if (a === b) {
        return typeof secondarySort === 'function' ? secondarySort(o, p) : 0;
      }
      if (typeof a === typeof b) {
        result = a < b ? -1 : 1;
      } else {
        result = typeof a < typeof b ? -1 : 1;
      }
      return result * reverseMutliplier;
    };
  }

  returnError(err: any): string {
    console.log(JSON.stringify(err));
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else if (err && err.error && err.error.detail) {
      return JSON.stringify(err.error.detail);
    } else if (err && err.error && err.error.Description && err.error.Description.length) {
      return JSON.stringify(err.error.Description[0]);
    } else {
      return JSON.stringify(err);
    }
  }
}
